@import "variables";
@import "~typeface-bad-script";
@import "~typeface-open-sans";
@import "~typeface-open-sans-condensed";
@import "~typeface-roboto";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~select2/dist/css/select2";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4";

@import "couleur";
@import "menu";
@import "custom";
@import "footer";
@import "voyants";
@import "home";
@import "horoscope";
@import "contact";
@import "astrologie";
//@import "astro-tibet";
@import "test";
//@import "audiotel";
@import "formulaire";
@import "tarot";
@import "carousel";
@import "blog";
@import "numerologie";
@import "responsive";
@import 'notification.scss';
//
//
//a {
//  color: $rose;
//}
//
//a:active, a:hover, a:visited {
//  color: $rose;
//  text-decoration: none;
//}
//
//h1, h2, h3, h4, h5 {
//  font-family: 'open sans condensed', sans-serif;
//  font-weight: bold;
//  text-transform: uppercase;
//  margin-top: 0;
//  margin-bottom: 0;
//}
//
///*** pagination ****/
//.pagination .page-item.active span {
//  background-color: $bleu-fonce;
//}
//
////***************** GENERAL ******************//
//
//body {
//  background-color: #fff;
//  font-family: 'Noto Sans JP', sans-serif;
//}
//
//h1, h2, h3, h4, h5 {
//  font-family: 'open sans condensed', sans-serif;
//  font-weight: bold;
//  text-transform: uppercase;
//  margin-top: 0;
//  margin-bottom: 0;
//}
//
//h2 {
//  margin-top: 0;
//  font-family: 'Open Sans Condensed', sans-serif;
//  font-weight: bold;
//  line-height: 40px;
//}
//
//.msg-alert {
//  position: relative;
//  z-index: 1500;
//  top: 20px;
//  margin-bottom: 0px;
//}
//
//.politique-confi a {
//  color: #000;
//}
//
//.span12 a {
//  color: #000;
//}
//
//.span6 a {
//  color: #000;
//}
//
//.separateur {
//  width: 30px;
//  height: 4px;
//  background-color: $rose;
//  margin-bottom: 12px;
//}
//
//.separateur-audio {
//  width: 30px;
//  height: 4px;
//  background-color: $color-audio;
//  margin-bottom: 30px;
//}
//
//.separateur-planning {
//  width: 90%;
//  height: 2px;
//  background-color: $rose;
//  margin-bottom: 30px;
//}
//
//.separateur-centre {
//  margin-left: auto;
//  margin-right: auto;
//}
//
//// todo: mettre dans carousel.scss ??
//.slider {
//  width: 50%;
//  margin: 100px auto;
//}
//
//// todo: mettre dans carousel.scss ??
//.slick-slide {
//  margin: 0 20px;
//}
//
//// todo: mettre dans carousel.scss ??
//.slick-slide img {
//  width: 100%;
//}
//
//.text-black {
//  color: $black;
//}
//
//.text-white {
//  color: $white;
//}
//
//.text-brand {
//  color: $brand-primary;
//}
//
//.text-privee {
//  color: #6865A2;
//}
//
//.container {
//  max-width: 1200px;
//  width: 90%;
//  margin-left: auto;
//  margin-right: auto;
//}
//
//.color-audio {
//  color: $color-audio;
//}
//
//.color-prive {
//  color: $color-prive;
//}
//
//.separator {
//  border-top: solid 1px $brand-primary-30;
//}
//
//.margin-top-10 {
//  margin-top: 10px;
//}
//
//.margin-top-20 {
//  margin-top: 20px;
//}
//
//.margin-top-30 {
//  margin-top: 30px;
//}
//
//.margin-top-50 {
//  margin-top: 50px;
//}
//
//.margin-top-75 {
//  margin-top: 75px;
//}
//
//.margin-top-100 {
//  margin-top: 100px;
//}
//
//.margin-bottom-10 {
//  margin-bottom: 10px;
//}
//
//.margin-bottom-20 {
//  margin-bottom: 20px;
//}
//
//.margin-bottom-30 {
//  margin-bottom: 30px;
//}
//
//.margin-bottom-50 {
//  margin-bottom: 50px;
//}
//
//.margin-bottom-75 {
//  margin-bottom: 75px;
//}
//
//.margin-bottom-100 {
//  margin-bottom: 100px;
//}
//
//// Notation Etoile
//
//.fa-star {
//  color: $gray-400;
//  //color: transparent;
//}
//
//.yellow {
//  color: $white;
//}
//
//#avis_etoile, .note-voyant {
//  .yellow {
//    color: $bleu-fonce;
//  }
//}
//
//.separator {
//  color: #ddd;
//}
//
//.alert.alert-info {
//  background-color: $brand-primary-10;
//  color: $brand-primary;
//
//  a {
//    color: $brand-primary;
//  }
//}
//
//.bloc-info {
//  background-color: rgba(0, 0, 0, 0.1);
//  padding: 70px 0;
//}
//.bloc-info2 {
//  background-color: rgba(0, 0, 0, 0.1);
//  padding: 70px 0;
//}
//
//
//.cadre-grey-hindoue {
//  background-color: rgba(0, 0, 0, 0.1);
//  padding: 3%;
//}
//
//.legal_card {
//  background-color: rgba(0, 0, 0, 0.1);
//  padding: 3%;
//}
//
//.border-grey {
//  border: solid 3px #f3f3f3;
//  padding: 20px;
//  height: 100%;
//}
//
//.title-blue {
//  color: $bleu-fonce;
//}
//
//.no-margin {
//  margin: 0;
//}
//
//a.white-underline {
//  color: white;
//  text-decoration: underline;
//}
//
//a.black-underline {
//  color: $brand-primary;
//  text-decoration: underline;
//}
//
////.presentation-katleen {
////  padding: 50px 0;
////  h2 {
////    margin-top: 5%;
////    font-family: 'open sans condensed', sans-serif;
////    font-weight: bold;
////  }
////}
//
////.test-accueil {
////  background-color: $brand-primary-10;
////  padding: 70px 0;
////  margin-bottom: -40px;
////
////  .titre {
////    h2 {
////      margin-bottom: 0;
////      font-family: 'open sans condensed', sans-serif;
////      font-weight: bold;
////      line-height: 40px;
////    }
////
////    h5 {
////      margin-top: -5px;
////    }
////  }
////
////  p {
////    font-size: 13px;
////    text-align: justify;
////  }
////
////  img {
////    width: 100%;
////  }
////}
//
//// Aligner verticalement des elements - à mettre sur le container
//.vcenter {
//  display: flex;
//  flex-flow: row wrap;
//  align-items: center;
//}
///* mit dans home.css car que sur index */
////.accueil-capital {
////  padding: 70px 0;
////  margin-bottom: -40px;
////
////  .titre {
////    h2 {
////      margin-bottom: 0;
////      font-family: 'open sans condensed', sans-serif;
////      font-weight: bold;
////      line-height: 40px;
////    }
////
////    h5 {
////      margin-top: -5px;
////    }
////  }
////
////  p {
////    font-size: 18px;
////    line-height: 26px;
////  }
////
////  img {
////    width: 100%;
////  }
////
////  a {
////    color: $bleu-fonce;
////
////  }
////}
//
//// todo: mettre dans carousel.scss ??
//#carousel {
//  .glyphicon {
//    display: none;
//  }
//}
//
//
///*PILLS*/
//.nav-pills .nav-item .nav-link.active {
//  color: #ffffff;
//  background-color: $bleu-fonce;
//  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 123, 255, 0.6);
//}
//
//.nav-pills .nav-item .nav-link {
//  line-height: 24px;
//  text-transform: uppercase;
//  font-size: 12px;
//  font-weight: 700;
//  min-width: 100px;
//  text-align: center;
//  color: #555;
//  transition: all .3s;
//  border-radius: 0;
//  padding: 10px 15px;
//}
//
///* Placement du thème des blocs sur les images */
//.with-subtheme {
//  position: relative;
//
//  .center-bottom,
//  .left-bottom-corner,
//  .left-middle-aligncenter,
//  .left-top-corner,
//  .left-top-corner-aligncenter,
//  .right-bottom-corner,
//  .right-bottom-corner-aligncenter,
//  .right-top-corner {
//    color: white;
//    font-family: 'open sans condensed', sans-serif;
//    font-weight: 700;
//    letter-spacing: 1px;
//    font-size: 1.5em;
//    text-transform: uppercase;
//    position: absolute;
//    text-shadow: 0 0 3px $brand-primary;
//  }
//
//  .center-bottom {
//    width: 100%;
//    text-align: center;
//    bottom: 10px;
//    left: 0;
//    right: 0;
//  }
//
//  .left-bottom-corner {
//    left: 20px;
//    bottom: 10px;
//  }
//
//  .left-middle-aligncenter {
//    left: 20px;
//    top: 50%;
//    text-align: center;
//  }
//
//  .left-top-corner {
//    left: 20px;
//    top: 10px;
//  }
//
//  .left-top-corner-aligncenter {
//    left: 20px;
//    top: 10px;
//    text-align: center;
//  }
//
//  .right-bottom-corner {
//    right: 20px;
//    bottom: 10px;
//  }
//
//  .right-bottom-corner-aligncenter {
//    right: 20px;
//    bottom: 10px;
//    text-align: center;
//  }
//
//  .right-top-corner {
//    top: 10px;
//    right: 20px;
//  }
//
//  /* Ajustements CSS */
//  .left-bottom-corner-an2014 {
//
//  }
//
//  .left-middle-aligncenter-an2014 {
//
//  }
//
//  .left-top-corner-aligncenter-an2014 {
//
//  }
//
//  .right-bottom-corner-aligncenter-an2014 {
//
//  }
//
//  .right-bottom-corner-an2014 {
//
//  }
//
//  .right-top-corner-an2014 {
//
//  }
//
//  .add-pad-an-2015 {
//    padding-right: 20px;
//  }
//
//}
//
//.sante-absolute {
//  position: absolute;
//  width: 35%;
//  left: 18%;
//  top: 42%;
//}
//
//.btn.btn-primary {
//  background-color: $bleu-fonce;
//}
//
//.btn.btn-primary:hover {
//  background-color: $bleu-fonce;
//}
//
//.btn.btn-primary-dark {
//  background-color: $bleu-fonce;
//}
//
//.btn.btn-primary-dark:hover {
//  background-color: $bleu-fonce;
//}
//
////FORMULAIRES
///* Champs date centré */
//#form_date_day,
//#form_date_month,
//#form_date_year,
//#_email_birthdate_day,
//#voyance_email_birthdate_month,
//#voyance_email_birthdate_year {
//  text-align: center;
//}
//
//.bmd-form-group legend {
//  color: $gray;
//}
//
//.form-check .form-check-label .circle .check {
//  background-color: $bleu-fonce !important;
//}
//
//.is-focused [class^="bmd-label"], .is-focused [class*=" bmd-label"], .is-focused select {
//  color: $bleu-fonce !important;
//}
//
//.bmd-form-group .bmd-label-static {
//  top: -10px !important;
//  text-transform: uppercase;
//}
//
///* GenreType inline */
//#form_genre,
//#recrutement_genre,
//#contact_genre {
//  div[class='radio'] {
//    display: inline-block;
//    text-transform: uppercase;
//  }
//}
//
//// todo: mettre dans un fichier recrutement.scss ou form.scss ??
///* Recrutement */
//form {
//  #same_as_genre {
//    height: 48px;
//    padding: 0 15px 7px;
//    margin: 27px 0 0 0;
//
//    p {
//      height: 100%;
//      margin: 0;
//      padding: 0;
//      line-height: 41px;
//    }
//  }
//
//  .double-input {
//    padding: 0 15px 14px;
//    height: 127px;
//    margin: 27px 0 0 0;
//
//    .control-label {
//      margin: 0;
//    }
//
//    textarea {
//      height: 100%;
//      padding: 0;
//      margin: 0;
//    }
//  }
//}
//
///* Connexion */
//// todo: mettre dans un fichier connexxion.scss ou form.scss ??
//form {
//  div.connexion-btn {
//    text-align: center;
//  }
//
//  .inscription {
//    width: 100%;
//  }
//}
//
//
//.btn:not(:disabled):not(.disabled) {
//  cursor: pointer;
//  float: right;
//}
//
//.bmd-form-group .bmd-label-static {
//  top: 0.35rem;
//  left: 0;
//  font-size: 10px;
//}
//
//.connexion {
//  background-color: rgba(0, 0, 0, 0.1);
//  padding-top: 1%;
//}
//
//.erreur h1 {
//  background-color: $bleu-fonce;
//  padding: 1%;
//}
//
//
////***************** FIN GENERAL ******************//
//
////********BLOG*****//
//.bloc-avis ul {
//  list-style: none;
//  border: 1mm ridge $bleu-fonce;
//  padding: 3%;
//}
//
//li.date {
//  background-color: $bleu-fonce;
//  padding: 1%;
//}
//
//
////********horoscope*****//
//.titre-horoscope h2 {
//  margin-top: 15px;
//}
//
//.horoscope {
//  background-color: $bleu-fonce;
//  padding: 2rem;
//  margin-top: 0;
//}
//
//.nav-horoscope {
//  float: left;
//  margin-bottom: 6%;
//  margin-left: 0%;
//  margin-top: 21%;
//}
//
//.acc-horoscope .categories-horoscope h1 {
//  text-align: center;
//  font-size: 26px;
//  color: #FA5F6D;
//  background-color: $bleu-fonce;
//  margin-top: 20px;
//  padding: 7px;
//}
//
//.acc-horoscope .categories-horoscope .citation h1 {
//  text-align: center;
//  font-size: 26px;
//  color: #fff;
//  background-color: $bleu-fonce;
//  margin-top: 20px;
//  padding: 7px;
//}
//
//.acc-horoscope .categories-horoscope p {
//  text-align: justify;
//  border: solid 0 #f3f3f3 !important;
//  padding: 15px;
//}
//
//.item_list.radius_shadow h5 {
//  background-color: #FA5F6D;
//  text-align: center;
//  border-radius: 10px;
//  padding: 2%;
//}
//
////*******REGISTER******//
//.form-group {
//  margin-bottom: 30px;
//  position: relative;
//}
//
//select.form-control:not([size]):not([multiple]) {
//  height: calc(1.5em + 0.75rem + 2px);
//  width: 33% !important;
//  color: #AAAAAA;
//}
//
//#app_registration_birthDate {
//
//
//}
//
//#app_registration_birthDate fieldset {
//  margin-top: 4rem;
//}
//
////*********reset_password**********//
//
//.bouton-request {
//  margin-right: 26%;
//}
//
//
////*********ERROR404**********//
//.error {
//  margin-top: 5%;
//  margin-bottom: 5%;
//}
//
//
////******RECRUTEMENT*******//
//.bmd-form-group .bmd-label-static {
//  top: 0em;
//  left: 0;
//  font-size: 15px;
//}
//
//.envoi {
//  margin-left: -18%;
//}
//
///* RESPONSIVE */
//
///* xs */
////@media screen and (max-width: 575px) {
////  /* Container */
////  //.info-katleen {
////  //  padding-top: 3rem;
////  //}
////  .presentation-katleen {
////    padding: 0px 0;
////  }
////  //.carousel-item img {
////  //  margin-top: 0rem !important;
////  //}
////}
//
///* md et plus */
//@media screen and (min-width: 768px) {
//  /* Container */
//  .tarot_container {
//    .hide-md {
//      display: none;
//    }
//
//    .show-md {
//      display: block;
//    }
//
//    .sequence1 {
//      .choice_zone {
//        a {
//          display: block;
//          width: 100%;
//          margin: 0;
//          float: left;
//        }
//      }
//    }
//
//    .sequence3 {
//      #results_wrapper {
//        flex-flow: row wrap;
//      }
//
//      .resultat_tarot {
//        width: 22%;
//      }
//
//      .tel_audio,
//      .tel_prive {
//        width: 50%;
//      }
//    }
//  }
//  //.presentation-katleen {
//  //  padding: 0 0;
//  //}
//  .nav-open .navbar-translate img {
//    margin-left: 13rem;
//  }
//  //.voyance-audiotel .bg_color_2 {
//  //  font-size: 13px!important;
//  //}
//}
//
//@media(max-width: 1199px) {
//  .mentions-footer {
//    margin-bottom: 40px;
//  }
//  .first-footer {
//    .avis-recents {
//      margin-top: 50px;
//    }
//  }
//}
//
//@media (max-width: 992px) {
//  .navbar-header {
//    float: none;
//  }
//  .navbar-toggle {
//    display: block;
//  }
//  .navbar-collapse {
//    border-top: 1px solid transparent;
//    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
//  }
//  /*
//    .navbar-collapse.collapse {
//      display: none!important;
//    }*/
//  .navbar-nav {
//    float: none !important;
//    margin: 7.5px -15px;
//  }
//  .navbar-nav > li {
//    float: none;
//  }
//  .navbar-nav > li > a {
//    padding-top: 10px;
//    padding-bottom: 10px;
//  }
//  .navbar-text {
//    float: none;
//    margin: 15px 0;
//  }
//  /* cette classe est à ajouter lorsque que vous utilisez une version de Bootstap supérieure ou égale à la 3.1.0*/
//  .navbar-collapse.collapse.in {
//    display: block !important;
//  }
//  .collapsing {
//    overflow: hidden !important;
//  }
//  footer {
//    .first-footer {
//      //padding: 0 0 30px 0;
//      section {
//        margin-top: 50px;
//      }
//    }
//  }
//}
//
///* Profile show */
////@media  screen and (min-width: 1170px) {
////  .deconnection {
////    margin-top: -40px;
////  }
////}
//
//.profil_show ul li a.editer {
//  color: $bleu-fonce;
//}
//
//.contain-signe {
//  min-height: 60px;
//}
//
//.horoscope p {
//  margin-top: 8%;
//}
//
///*login */
//
//
//.nav-item.active .dropdown-menu .dropdown-item:hover, .nav-item.active .dropdown-menu .dropdown-item:focus, .nav-item.active .dropdown-menu .dropdown-item:active, .nav-item.active .dropdown-menu a:active, .nav-item.active .dropdown-menu a.active, .nav-item.active .dropdown-menu a:hover, .nav-item.active .dropdown-menu a:focus, .nav-item.active .dropdown-menu a:active {
//  background-color: $bleu-fonce !important;
//}
//
//.navbar .dropdown.show .dropdown-menu, .navbar .dropdown .dropdown-menu {
//  height: auto;
//}
//
//.exemple-cabinet a {
//  color: #000;
//}
//
//.exemple-cabinet a:hover {
//  color: $bleu-fonce;
//}
//
///*** Popup modal promo ****/
//
//.modal-ecran {
//  margin: 20px;
//}
//
//.hide-modal {
//  width: 250px;
//  position: absolute;
//  margin: 0 auto;
//  right: 0;
//  left: 0;
//  bottom: 20px;
//  z-index: 9999;
//}
//
//#modal-ecran {
//  .modal-header {
//    padding-left: 7rem;
//  }
//
//  .modal-body {
//    h5 {
//      text-transform: none;
//    }
//  }
//
//}
//
//#imgPopupPromo {
//  object-fit: none;
//  object-position: left;
//  height: 10rem;
//}
//
///*** Fin code Modal promo ****/
//
//.alert.alert-warning {
//  color: #856404;
//  background-color: #fff3cd;
//  border-color: #ffeeba;
//}
//
///*** couleur numero privee ****/
//h3 {
//  a {
//    color: $bleu-fonce;
//  }
//
//  a:hover {
//    color: $bleu-fonce;
//  }
//}
//
//
//blockquote {
//  padding: 10px 20px;
//  margin: 0 0 20px;
//  font-size: 17.5px;
//  border-left: 5px solid #eeeeee;
//}
//
//.horo-zodiac-blanc {
//  filter: brightness(0) invert(1);
//  -webkit-filter: brightness(0) invert(1);
//}
//
//// MOTEUR DE RECHECHE
//li.select2-results__option a {
//  color: white;
//  text-transform: uppercase;
//}
//li.select2-results__option a:hover {
//  color: black;
//  text-transform: uppercase;
//  text-decoration: underline;
//}
//.select2-container--open .select2-dropdown--below {
//  background-color: $bleu-fonce;
//}
//.select2-container--open .select2-dropdown--above {
//  background-color: $bleu-fonce;
//}
//.navbar-nav {
//  flex-direction: column;
//  padding-left: 0;
//  margin-bottom: 0;
//  list-style: none;
//}
//
//.titre-page h1 {
//  font-size: 3.3125rem;
//}
//form {
//  margin-top: 0;
//  margin-bottom: 10px;
//}
//.modal-header .close {
//  padding: 0rem 0;
//}
//#toast-title, i {
//  .yellow-audiotel {
//    color: $color-audio;
//  }
//  .yellow-private {
//    color: $bleu-fonce;
//  }
//}
//#toast-time, span {
//  .private {
//    background-color: $bleu-fonce;
//  }
//  .audiotel {
//    background-color: $color-audio;
//  }
//}
//.toast{
//  background-color:#fff;
//}
//.toast-footer{
//  a.private {
//    color:$bleu-fonce;
//    border-color:$bleu-fonce;
//  }
//  a.private:hover{
//    color:$bleu-fonce;
//    border-color:$bleu-fonce;
//    background-color:rgba(122, 184, 176, 0.1);
//  }
//  a.audiotel{
//    color:$color-audio;
//    border-color:$color-audio;
//  }
//  a.audiotel:hover{
//    color:$color-audio;
//    border-color:$color-audio;
//    background-color:rgba(165, 16, 120, 0.1);
//  }
//}
//
///**** Detail voyants ****/
//.white-space-normal{
//  white-space: normal;
//}
///**** bandeau pub ****/
//.bobo {
//  cursor: pointer !important;
//}
//
