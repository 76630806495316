///*====================== TESTS ======================*/
//
//#progressbar {
//  height: 50px;
//  width: 100%;
//  background-color: #fff;
//  border: 0.5px solid rgba(50, 50, 50, 0.2);
//  border-radius: 5px;
//  margin-top: 4%;
//  margin-bottom: 1%;
//}
//#progress-label {
//  width: 0;
//  height: 100%;
//  background-color: rgb(160,217,210);
//  text-align: center;
//  line-height: 50px;
//  transition: 0.4s linear;
//}
//.range {
//  height: 50px;
//  margin: 30px 0;
//}
//input.choix {
//  width: 100%;
//  text-align: center;
//  height: 50px;
//  margin: 30px 0;
//}
//.range-item {
//  float:right;
//  height: 100%;
//  width: 10%;
//  border: 0.25px solid #000;
//}
//.range-item p {
//  text-align: center;
//  line-height: 50px;
//}
//.question {
//  display: flex;
//  align-items: center;
//  flex-wrap: wrap;
//  h2 {
//    //font-size: 2.2em;
//  }
//}
//#wizard {
//  .well {
//    //background-color: rgba(0, 0, 0, 0.1);
//    padding: 3%;
//    border-radius: 0;
//    border: none;
//    box-shadow: none;
//  }
//}
//.bt_test a{
//   margin-top: 3%;
//   margin-bottom: 3%;
// }
//
//#qcm_container {
//  margin-top: 2%;
//}
//
//.qcm-test h2{
//  margin-top: 4%;
//  margin-bottom: 2%;
//  font-size: 25px;
//}
////.reponses {
////  margin-left: 16%;
////  margin-bottom: 1%;
////}
//.reponses {
//  margin-left: 35%;
//  margin-bottom: 0;
//}
//
//.question-4.reponses {
//  text-align: center;
//  margin-bottom: 4%;
//}
//
//.btn-group, .btn-group-vertical {
//  margin-top: 10%;
//}
//.bouton_qcm {
//  background: #fff !important;
//  color: #000 !important;
//  font-weight: bold;
//  font-size: 15px;
//  border: 0.25px solid #000 !important;
//}
//
//
///*====================== MATERIAL KIT ======================*/
//.form-check .form-check-label .circle .check {
//  background-color: #000000;
//}
//.img_qcm img{
//  width: 80%;
//  margin-left: 30%;
//  margin-top: 16%;
//}
//
//
//:required
//{
//  background-color: rgba(117, 190, 218, 0.0);
//}
//
//
///*----------------------TESTS---------------------*/
//
//@media screen and (max-width: 575px) {
//  .question h2 {
//    text-align: center;
//    font-size: 15px;
//  }
//  .reponses {
//    margin-left: 12%;
//  }
//  .img_qcm img {
//    width: 3rem;
//  }
//  .nav-qcm.btn {
//    width: 100%;
//  }
//  .question-4 .reponses .btn-group .form-control {
//    width: max-content !important;
//  }
//  .question-6 .reponses .btn-group .form-control {
//    width: max-content !important;
//  }
//  .question-12 .reponses .btn-group .form-control {
//    width: max-content !important;
//  }
//  .question-18 .reponses .btn-group .form-control {
//    width: max-content !important;
//  }
//  .question-21 .reponses .btn-group .form-control {
//    width: max-content !important;
//  }
//  .bouton_qcm {
//    width: 9%;
//    background: #fff !important;
//    color: #000 !important;
//    font-weight: bold;
//    font-size: 12px;
//    border: 0.25px solid #000;
//  }
//  .btn-group, .btn-group-vertical {
//    margin-top: 10%;
//    margin-left: -1rem;
//  }
//  .form-check .form-check-label .circle {
//    margin-left: -1rem;
//  }
//
//  #qcm_container .stretch .bloc-num h2 {
//    font-size: 20px;
//    line-height: 27px;
//  }
//}
//@media screen and (min-width: 576px) {
//  .nav-qcm {
//    .btn {
//      width: 200px;
//    }
//  }
//  .form-check .form-check-label .circle {
//    border: 1px solid rgba(0,0,0, .54);
//    height: 15px;
//    width: 15px;
//    border-radius: 100%;
//    top: 1px;
//    margin-left: -1rem;
//  }
//  .blog_detail h1 {
//    font-size: 35px;
//    text-align: center;
//  }
//  .question-6 .reponses .btn-group{
//    margin-left: -25%;
//  }
//
//  .question-12 .reponses {
//    margin-left: -2%;
//    margin-bottom: 4%;
//  }
//
//  .question-18 .reponses .btn-group{
//    margin-left: -20%;
//  }
//
//  .question-21 .reponses {
//    margin-bottom: 4%;
//  }
//}
//@media screen and (min-width: 768px) {
//  .reponses {
//    margin-left: 18%;
//    margin-bottom: 0;
//  }
//  .question-4 .reponses .btn-group {
//    margin-left: -2%;
//  }
//  .question-21 .reponses .btn-group {
//    margin-left: -2%;
//  }
//  .question-6 .reponses .btn-group {
//    margin-left: -2%;
//  }
//}
//
//@media screen and (min-width: 576px) and (max-width: 767px){
//  .next, .prev, #envoyer {
//    width: 100%;
//  }
//}
//@media screen and (min-width: 576px) and (max-width: 991px) {
//  .question-12 .reponses .btn-group {
//    margin-left: 20%;
//  }
//}
//@media screen and (min-width: 992px){
//  .question {
//    h2 {
//      font-size: 2.2em;
//    }
//  }
//  .question-21 .reponses .btn-group {
//    margin-left: -20%;
//  }
//  .question-21 .reponses .btn-group {
//    margin-left: -3%;
//  }
//  .question-18 .reponses .btn-group {
//    margin-left: -2%;
//  }
//}
//
//@media screen and (min-width: 991px) {
//
//  .question-12 .reponses {
//    margin-left: 16%;
//    margin-bottom: 4%;
//  }
//
//  .question-21 .reponses {
//    margin-bottom: 4%;
//  }
//  .maincontent img {
//    width: 424px;
//    height: 283px;
//    margin-left: 25%;
//    margin-bottom: 4%;
//  }
//}
//@media screen and (min-width: 1440px) {
//  .question .form-check .form-check-label {
//    padding-left: 1% !important;
//  }
//  .question-4 .reponses .btn-group {
//    margin-left: -2%;
//    margin-top: 4%;
//  }
//}
//
//
//.form-control{
//  width: auto;
//}
//.form-check .form-check-input:checked~ .circle{
//  border-color: rgba(0,0,0, 0.54);
//}

#qcm_container {
  margin-top: 2%;
}

#block2 {
  #progressbar {
    height: 50px;
    width: 100%;
    background-color: $white;
    border: 0.5px solid rgba(50, 50, 50, 0.2);
    border-radius: 5px;
    margin-top: 4%;
    margin-bottom: 1%;
    #progress-label {
      width: 0;
      height: 100%;
      background-color: rgb(160,217,210);
      text-align: center;
      line-height: 50px;
      transition: 0.4s linear;
    }
  }
  #carouselExampleIndicators {
    .question{
      h2 {
        font-size: 15px;
        line-height: normal;
      }
      .img_qcm img {
        margin-left: 30%;
      }
    }
    .reponses {
      margin-left: 6%;
      .form-check  {
        label {
          color: $grey;
          font-size: .875rem;
        }
      }
      .form-check-input{
        border: 1px solid rgba(0,0,0,.54);
      }
      .form-check-input:checked {
        background-color: $bleu-fonce;
        border-color: $bleu-fonce;
      }
      .form-check-input:focus {
        box-shadow: none;
      }
      .form-control:focus{
        box-shadow: none;
      }
      .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        .bouton_qcm {
          background-color: $white;
          color: $black-color;
          font-weight: bold;
          font-size: 15px;
          border: 0.25px solid $black-color;
          border-radius: 0;
          input{
            border-radius: 100%;
          }
        }
        .bmd-form-group {
          select.form-control:not([size]):not([multiple]) {
            color: $grey;
          }
          .form-control {
            border: none;
          }
        }
      }
    }
  }
}