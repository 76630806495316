@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

/**** carousel Details voyants ****/
.carousel-item img {
  width: 100%;
}

.carousel {
  .carousel-indicators .active {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }

  .carousel-indicators {
    bottom: 5px;
    top: 103%;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      text-indent: -999px;
      cursor: pointer;
      border: 1px solid $white;
      background: $white;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
      border-radius: 2px;
    }
  }
}


.slider-top {
  padding-top: 4rem;
}

.slider {
  width: 50%;
  margin: 100px auto;
}

.slick-slide {
  margin: 0 20px;
}

.slick-slide img {
  width: 100%;
}

#carousel {
  .glyphicon {
    display: none;
  }
}


@media only screen and (max-width: 576px) {
  .bloc-heure > span:first-child:after {
    content: ' - ';
  }
}

@media only screen and (min-width: 577px) {
  .bloc-heure > span:first-child:after {
    content: '\A';
    white-space: pre;
  }
}

.bloc-heure.active {
  //background-color: $bleu-fonce;
  color: $white;

  a {
    color: $white;
  }
}

/*====================================================
// CAROUSEL Planning Privée / Audiotel
//===================================================*/
.bloc-heure {
  height: 30px;
  background-color: rgb(221, 221, 221);
  color: black;
  text-align: center;
  border-right: solid 1px $white;
  line-height: 30px;
  float: left;
  margin: 0;
  cursor: pointer;
  position: relative;
  border: solid 0.5px white;
}

.bloc-heure a {
  color: black;
  text-align: center;
}

@media screen and (min-width: 577px) {
  .bloc-heure {
    height: 50px;
    line-height: 25px;
  }
}

.slick-slide {
  margin: 0 !important;
}

.selected-hours {
  background-color: rgb(250, 95, 109);
  color: $white;
  font-size: 110%;
}

.planning-voyance-audiotel .container-heure .active,
.planning-voyance-audiotel .container-heure .bloc-heure:hover,
.planning-voyance-audiotel .one-date:hover {
  background-color: $color-audio;
  color: $white;
}

.planning-voyance-privee .container-heure .active,
.planning-voyance-privee .container-heure .bloc-heure:hover,
.planning-voyance-privee .one-date:hover {
  background-color: $bleu-fonce;
  color: $white;
}

/* ATTENTION : enlever pour la version mobile (slider vertical) */

.container-heure .active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $bleu-fonce;
}

.planning-voyance-audiotel .container-heure .active:after {
  border-top: 10px solid $color-audio;
}

@media screen and (max-width: 576px) {
  .container-heure .active:after {
    display: none;
  }

}

/* slick-theme.css - pour version mobile avec slider vertical */

.slick-vertical .slick-slide {
  border: 0.5px solid $white;
}

/*====================================================
// CAROUSEL Page Accueil
//===================================================*/

.voyants-moment {
  background-color: $white;
}

.voyants-moment .slick-slide {
  margin: 0 0 20px;
}


//**** RESPONSIVE ****//

.slick-prev::before, .slick-next::before {
  background-color: $white;
}

.slick-prev, .slick-next, .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  height: 100%;
  width: 24px;
  background: $white;
}

.slick-prev::before, .slick-next::before {
  color: #000;
}

.bloc-voyants {
  @media screen and (min-width: 576px) {
    .fiche-voyant {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}

.slick-vertical .slick-slide {
  border: none !important;
}

@media screen and (min-width: 577px) {
  .container-heure .slick-list.draggable {
    min-height: 60px;
  }
}
