@media screen and (min-width: 576px) {
  h1 {
    font-size: 2.3125rem;
  }
  #toast-parent{
    right: 30px;
  }
  /* header */
  header {
    .header-top {
      height: auto;
      background-color: $white-800;
    }
    .navbar .navbar-translate .logo{
      width: auto;
    }
  }
  .nav-open .navbar-translate img {
    margin-left: 13rem;
  }
  /* Carousel pages */
  .slider-top {
    padding-top: 5rem;
  }
  .carousel .carousel-indicators li {
    margin: 0 2%;
  }
  /* Img entête accueil */
  .entete{
    padding-top: 4rem;
  }
  /* page accueil */
  .text-intro img, .voyance-audiotel img.numero-audio{
    width: 80%;
  }
  .voyance-audiotel .btn-audiotel{
    font-size: 12px;
  }
  #fiche-perso {
    .header-fiche .disponibilite {
      font-size: 24px;
    }
    .planning-audiotel-voyant img.img-tel{
      width: 70%;
    }
    .planning-audiotel-voyant h2, .planning-prive-voyant h2{
      font-size: 18px;
    }
    .planning-prive-voyant {
      h3 {
        font-size: 28px;
      }
      h4.tarif {
        font-size: 16px;
      }
    }
  }
  .section-voyance .numero-prive h2{
    font-size: 28px;
  }

  .liste-audiotel img.img-tel{
    width: 75%;
  }
  /* tarot */
  #detailsTarot {
    .tarot_container {
      #resultCard .emplacementCarte {
        width: 85px;
      }

      #cartesdos_wrapper .imgTarot {
        width: 20%;
      }
    }
    .tarotForm .card {
      .card_name{
        padding: 6% 0;
      }
      .imgRep {
        width: 140%;
      }
    }
  }
  #listTarot {
    .accesTirage {
      width: 72.6%;
    }
  }
  /**** Horoscopes ****/
  .acc-horoscope {
    .bloc-sign {
      min-height: 21rem;
    }
  }

  /***** Test *****/
  #block2 #carouselExampleIndicators {
    .question {
      .img_qcm img {
        width: 75%;
      }
      h2 {
        font-size: 19px;
      }
    }
    .reponses{
      margin-left: 17%;
    }
    .question .reponses .btn-group{
      margin-left: -20%;
    }
  }
  #register form #app_registration_telephone .input-group-prepend{
    width: 50%;
  }
  #app_profile_emailHoroscopeTypes, #app_registration_emailHoroscopeTypes {
    display: inline-flex;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 3.3125rem;
  }
  h2{
    font-size: 2rem;
  }
  /* Carousel pages */
  .slider-top {
    padding-top: 5.2rem;
  }
  .entete {
    padding-top: 3.6rem;
  }
  .carousel .carousel-indicators {
    top: 110%;
    li {
      margin: 0 1%;
    }
  }
  /* page accueil */
  .text-intro {
    background-color: $bleu-fonce;
    padding: 2rem;
    img {
      width: auto;
    }
  }
  .accueil-capital p{
    font-size: 16px;
    margin-top: 0;
  }
  .voyance-audiotel .btn-audiotel {
    font-size: 13px;
  }
  .section-voyance-audiotel .date-picker .active::after, .section-voyance-privee .date-picker .active::after {
    position: relative;
    top: 4.2rem;
    right: 30%;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .liste-voyants {
    img.img-tel {
      width: 50%;
    }
    .lien-detail .presa {
      min-height: 13rem;
    }
  }
  #fiche-perso {
    .nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
      margin-left: 5px;
    }
    .nav-pills .nav-item .nav-link{
      font-size: 10px;
    }
    .fiche-voyant {
      .header-fiche {
        .presentation-voyant h2 {
          font-size: 52px;
        }
      }

      .boutons-voyant .btn-voyant {
        min-height: 6rem;
      }
    }
    .planning-audiotel-voyant, .planning-prive-voyant {
      h2{
        font-size: 20px;
      }
    }
    .planning-audiotel-voyant img.img-tel{
      width: 60%;
    }
    .planning-prive-voyant h3{
      font-size: 40px;
    }
  }
  .section-voyance .numero-prive {
    padding-top: 12px;
    padding-bottom: 12px;
    h2{
      font-size: 26px;
    }
  }
  .section-voyance-audiotel .date-picker .active::after, .section-voyance-privee .date-picker .active::after {
    right: 50%;
  }
  /* tarot */
  #detailsTarot {
    .tarot_container {
      #resultCard .emplacementCarte {
        width: 110px;
        height: 180px;
      }
      #cartesdos_wrapper .imgTarot {
        width: 15%;
      }
    }
    .tarotForm .card {
      height: 30rem;
      overflow-y: auto;
      overflow-x: hidden;
      .imgRep {
        width: 50%;
      }
    }
  }
  #listTarot {
    .accesTirage {
      width: 53.7%;
    }
  }
  /**** Horoscope ****/
  .acc-horoscope {
    .titre-bloc h2 {
      font-size: 15px;
    }
    .bloc-sign {
      min-height: 18rem;
    }
    .categories-horoscope {
      .ete.block-couple, .ete.block-celibataire {
        .categories-details p {
          min-height: 13rem;
        }
      }
      .annuel img, .amour img{
        margin-top: 40%;
      }
    }
  }
  .astrologie {
    .bloc-astro.domaine {
      min-height: 39rem;
    }
  }
  /**** test ****/
  #block2 #carouselExampleIndicators {
    .question {
      .img_qcm img {
        width: 85%;
      }
      h2 {
        font-size: 22px;
      }
    }
  }
  #contact form #contact_form_telephone .input-group-prepend {
    width: 50%;
  }
  .puce_katleen.puce2 li {
    font-size: 16px;
  }
  #editShow, #register {
    .services .form-check-label:not(.last) {
      margin-right: 14px;
    }
  }
}
@media screen and (min-width: 992px) {
  .btn{
    padding: 12px 30px;
  }
  /* page accueil */
  .text-intro img{
    width: 75%;
  }
  .accueil-capital .btnCapital, .voyance-audiotel .btn-audiotel {
    padding: .5rem 1rem;
  }
  .section-voyance-audiotel .date-picker, .section-voyance-privee .date-picker {
    .active{
      font-size: 18px;
    }
    .active::after{
      top: 3.3rem;
    }
  }
  .section-voyance .numero-prive h2{
    font-size: 32px;
  }
  .voyance-audiotel img.numero-audio{
    width: 60%;
  }
  .presentation-katleen {
    margin-top: 0;
  }
  #fiche-perso {
    .nav-pills .nav-item .nav-link {
      font-size: 12px;
    }
    .header-fiche .disponibilite {
      font-size: 28px;
    }
    .fiche-voyant .boutons-voyant .btn-voyant {
      min-height: 5.2rem;
    }
    .planning-audiotel-voyant img.img-tel{
      width: 50%;
    }
  }
  .liste-voyants img.img-tel {
    width: 40%;
  }
  /* tarot */
  #detailsTarot {
    .tarot_container {
      #cardCont {
        margin-bottom: 4rem;
      }
      #cartesdos_wrapper {
        text-align: start;
        .imgTarot {
          width: auto;
        }
        .positionAbsolute {
          position: absolute;
        }
      }
      #resultCard .emplacementCarte {
        width: 160px;
        height: 250px;
      }
      .marginTarot {
        margin-top: 20rem;
      }
    }
  }
  #listTarot{
    .accesTirage {
      width: 100%;
    }
    .titre-page{
      margin-bottom: 60px;
    }
  }
  /**** Horoscope ****/
  .acc-horoscope {
    .bloc-sign {
      min-height: 20rem;
    }
    .categories-horoscope {
      .printemps .minH.categories-details {
        min-height: 24rem;
      }
      .printemps.block-bien-etre img, .printemps.block-famille img {
        margin-top: 10%;
      }
      .printemps.messieurs img, .automne img, .hiver img{
        width: 80%;
      }
      .ete.block-couple, .ete.block-celibataire{
        .categories-details p{
          min-height: 10rem;
        }
      }
      .annuel img {
        margin-top: 18%;
        width: 80%;
      }
      .amour img {
        margin-top: 20%;
      }
    }
  }
  .astrologie {
    .bloc-astro.domaine {
      min-height: 43rem;
    }
    .text-astro {
      font-size: 22px;
    }
  }
  .resultat-astrologie-hindoue .preface{
    padding-left: 0;
  }
  /**** Test ****/
  #block2 #carouselExampleIndicators .question .img_qcm img {
    width: 75%;
  }
  /* Footer */
  footer {
    .first-footer {
      .articles-recents, .avis-recents {
        padding-bottom: 0;
      }
    }
    .mentions-footer .btn-primary-dark{
      padding: 0.5rem 1rem;
    }
  }
}
@media screen and (min-width: 1200px) {
  body{
    padding-top: 95px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 46px;
    overflow: inherit;
  }
  .recherche-mobile {
    all: initial;
    i {
      font-size: initial;
      color: black;
    }
  }
  .recherche-close {
    all: inherit;
  }
  /* Header */
  header {
    .header-top {
      padding: 5px 20px 5px 20px;
      .navbar-text {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        p {
          padding: 0;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
    .navbar {
      padding: 11px 0;
      .navbar-translate{
        width: auto;
      }
      #navbarNavDropdown.navbar-collapse {
        all: initial;
        font-family: inherit;
        &::after, .navbar-nav .nav-item::after {
          all: initial;
          font-family: inherit;
        }
        /*NavBar avec les reseaux sociaux*/
        .reseaux-sociaux{
          padding: 0 8px;
          color: $rose;
          &:hover{
            color: $black-color;
          }
        }
        .menuLink{
          .nav-item .nav-link {
            color: $gray;
          }
        }
        /*fin*/
        .navbar-nav {
          align-items: center;
          z-index: initial;
          .nav-item.active {
            .nav-link {
              background-color: transparent;
              color: inherit;
              border-bottom: 2px solid #7AB8B0;
              margin-right: 0;
            }
            .nav-link:hover, .nav-link:active, .nav-link:focus {
              margin-right: 0;
              box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.01), 0 7px 10px -5px $bleu-shadow;
              background-color: #e9ecef;
              color: inherit;
            }
            .dropdown-menu.show .nav-link{
              border: none;
            }
          }
          .nav-item {
            padding: 0;
            .nav-link {
              margin: 0;
              padding: 0 8px;
            }
            .dropdown-menu {
              overflow: hidden;
              max-height: initial;
              .nav-link{
                padding: 0.9375rem;
              }
            }
            .dropdown-toggle::after {
              position: initial;
              right: 0;
              margin-top: 8px;
            }
            .dropdown-toggle.show::after {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  /* Carousel pages */
  .slider-top {
    padding-top: 1.5rem;
  }
  /* Img entête accueil */
  .entete {
    padding-top: 0;
  }
  /* page accueil */
  .text-intro img{
    width: 60%;
  }
  .liste-voyants {
    //.fiche-voyant p {
    //  font-size: 11px;
    //}
    img.img-tel {
      width: 35%;
    }
  }
  #fiche-perso {
    .fiche-voyant {
      .header-fiche {
        .avis .btn-avis {
          padding: 10px 4px;
        }
      }

      .boutons-voyant .btn-voyant {
        font-size: 14px;

        #typeTelDetail {
          margin: 0;
        }
      }
    }
    .planning-prive-voyant, .planning-audiotel-voyant{
      .jour p{
        font-size: 16px;
      }
    }
    .planning-audiotel-voyant img.img-tel{
      width: 40%;
    }
  }
  .section-voyance-audiotel .date-picker .active::after, .section-voyance-privee .date-picker .active::after {
    right: 54%;
  }
  /* Tarot */
  #detailsTarot .tarot_container {
    #resultCard .emplacementCarte {
      width: 165px;
      height: 265px;
    }
  }
  .acc-horoscope {
    .bloc-sign {
      min-height: 18rem;
    }
    .categories-horoscope {
      h2{
        font-size: 20px;
      }
      .printemps .minH.categories-details {
        min-height: 20rem;
      }

      .printemps.block-bien-etre img, .printemps.block-famille img {
        margin-top: 36%;
      }
      .printemps.messieurs img, .ete img, .annuel img {
        width: 70%;
      }
      .ete.block-couple, .ete.block-celibataire{
        .categories-details p{
          min-height: 8rem;
        }
      }
      .amour img {
        margin-top: 15%;
      }
    }
  }
  .astrologie .bloc-astro.domaine {
    min-height: 40rem;
  }
  .resultat-astrologie-hindoue .resultat-signe .img-signe{
    width: auto;
  }
  /**** Test ****/
  #block2 #carouselExampleIndicators .question .img_qcm img {
    width: 65%;
  }
  .puce_katleen.puce2 li {
    font-size: 18px;
  }
}
@media screen and (min-width: 1400px) {
  /* header */
  header{
    .navbar #navbarNavDropdown.navbar-collapse {
      .reseaux-sociaux, .navbar-nav .nav-item .nav-link {
        padding: 0 15px;
      }
    }
  }
  /* page accueil */
  .text-intro img{
    width: 50%;
  }
  .voyance-audiotel img.numero-audio{
    width: 50%;
  }
  #fiche-perso .fiche-voyant .boutons-voyant .btn-voyant {
    min-height: 4rem;
  }
  .section-voyance-audiotel .date-picker .active::after, .section-voyance-privee .date-picker .active::after {
    right: 56%;
  }
  .liste-voyants img.img-tel {
    width: 26%;
  }
}
@media screen and (min-width: 1600px) {
  .section-voyance-audiotel .date-picker .active::after, .section-voyance-privee .date-picker .active::after {
    right: 53%;
  }
}
@media screen and (min-width: 1800px) {
  .carousel .carousel-indicators li {
    margin: 0 0.7%;
  }
  /* page accueil */
  .text-intro img {
    width: 45%;
  }
}
@media screen and (min-width: 2800px) {
  .carousel .carousel-indicators {
    top: 105%;
  }
}
