/*----------------------PAGE ACCUEIL---------------------*/
.entete{
  padding-top: 2rem;
}
.text-intro {
  background-color: $bleu-shadow;
  padding: 1rem;
  margin-top: -1.5rem;
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  .numeros {
    font-size: 28px;
  }
  .text-black a {
    color: $black-color;
  }
}
.info-katleen {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.accueil-capital {
  padding: 70px 0;
  margin-bottom: -40px;
  .btnCapital{
    padding: 1rem;
    font-size: .875rem;
    line-height: 1.33333;
    border-radius: .2rem;
  }
  .titre {
    h2 {
      margin-bottom: 0;
      font-family: 'open sans condensed', sans-serif;
      font-weight: bold;
      line-height: 40px;
    }
    h5 {
      margin-top: -5px;
    }
  }
  p {
    line-height: 26px;
    margin-top: 1rem;
    a {
      color: $bleu-fonce;
    }
  }
}
.voyance-audiotel {
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: rgba(0, 0, 0, 0.1);
  .btn-audiotel {
    font-size: 10px;
  }
}
.presentation-katleen {
  padding: 0;
  margin-top: 4rem;
  h2 {
    margin-top: 5%;
    font-family: 'open sans condensed', sans-serif;
    font-weight: bold;
  }
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid $grey-light;
  }
}
.h-fit-content {
  height: fit-content!important;
}