//.acc-horoscope{
//  margin-bottom: 20px;
//  .horoscope{
//    padding-top:40px;
//    margin-top: 30px;
//    background-color: #a0d9d2;
//  }
//  .description-horoscope{
//    padding: 5%;
//    background-color: #f6f6f6;
//    margin-bottom: 15px;
//    position: relative;
//    p{
//      margin-top: 30px;
//      margin-left: 0%;
//    }
//    img{
//      width: 100%;
//    }
//  }
//  .bloc-sign{
//    background-color: #f6f6f6;
//    height: 100%;
//    min-height: 290px;
//    color: #333;
//    overflow: hidden;
//    margin-bottom: 10px;
//    a{
//      color: $brand-primary;
//    }
//    img{
//      width: auto;
//      height: 50px;
//      margin-top: 45px;
//      margin-left: 30%;
//      text-align: center;
//    }
//    .mini-horoscope{
//      margin-top: 7%;
//      a{
//        font-weight: bold;
//      }
//      p{
//        padding-right: 5px;
//      }
//    }
//  }
//  .titre-bloc{
//    background-color: $rose;
//    margin-top: 10px;
//    h2{
//      text-align: center;
//      margin: 0;
//      font-size: 14px;
//      color: $white;
//    }
//  }
//  .nav-horoscope{
//    h3{
//      text-align: center;
//      background-color: $rose;
//      padding: 15px;
//      color: white;
//      margin-top: 0;
//      text-transform: uppercase;
//      letter-spacing: 2px;
//      font-size: 20px;
//      margin-bottom: 10px;
//    }
//    ul{
//      padding: 0;
//      margin: 0;
//      li{
//        list-style-type: none;
//        background-color: $brand-primary-10;
//        padding: 10px;
//        margin-bottom: 5px;
//        text-align: center;
//        text-transform: uppercase;
//        letter-spacing: 2px;
//        font-size: 11px;
//        font-weight: 500;
//        transition-duration: 0.5s;
//        line-height: 14px;
//        a{
//          color: $brand-primary;
//        }
//      }
//      li:hover{
//        background-color: $brand-primary;
//        transition-duration: 0.5s;
//        a{
//          color: white;
//        }
//        a:hover{
//          text-decoration: none;
//        }
//      }
//      li.active{
//        background-color: $bleu-fonce;
//      }
//    }
//  }
//  .categories-horoscope{
//    margin-bottom: 20px;
//    h2{
//      text-align: center;
//      font-size: 26px;
//      color: $rose;
//      background-color: $bleu-fonce;
//      margin-top: 20px;
//      padding: 7px;
//      .fa{
//        transform: rotate(-4deg);
//        font-size: 22px;
//        margin-right: 5px;
//      }
//    }
//    p{
//      text-align: justify;
//      border: solid 3px #f3f3f3;
//      padding: 15px;
//    }
//    .p-jour{
//      height: 300px;
//    }
//    .p-semaine{
//      height: 200px;
//    }
//    .p-mois{
//      height: 350px;
//    }
//  }
//  .citation {
//    background-color: $bleu-fonce;
//    p {
//      font-size: 22px;
//      text-align: center !important;
//      font-family: 'open sans condensed', sans-serif;
//      letter-spacing: 1px;
//    }
//    .fa{
//      margin: 0 10px;
//    }
//  }
//  .nombre-de-chance p, .clin-d-oeil p {
//	  text-align: center !important;
//  }
//  .alert-horo{
//    background-color: #f3f3f3;
//    text-align: center;
//    height: 150px;
//    color: $brand-primary;
//    p{
//      font-size: 18px;
//    }
//  }
//  .alert-conseil{
//    background-color: $bleu-fonce;
//    text-align: justify;
//    color: white;
//  }
//  .lien-detail-horoscope-printemps,
//  .lien-detail-horoscope-semaine,
//  .lien-detail-horoscope-mois,
//  .lien-detail-horoscope-ete,
//  .lien-detail-horoscope-automne,
//  .lien-detail-horoscope-hiver,
//  .lien-detail-horoscope,
//  .lien-detail-horoscope-amour,
//  .lien-detail-horoscope-2017{
//    cursor: pointer;
//  }
//  .bloc-amour{
//    min-height: 290px;
//  }
//}
//
//.section-horoscope-printemps{
//  .horoscope-printemps{
//    .bloc-4{
//      height: 570px;
//      img{
//        display: block;
//        margin-left: auto;
//        margin-right: auto;
//      }
//    }
//    .bloc-2{
//      height: 315px;
//    }
//    .bloc-horoscope-printemps{
//      border: solid 3px #f3f3f3;
//      margin-top: 30px;
//      padding: 25px;
//      h3{
//        margin-bottom: 10px;
//      }
//      img{
//        margin-bottom: 10px;
//      }
//    }
//  }
//
//}
//
//.horoscope-amour {
//  width: 335px;
//  position: absolute;
//  bottom: 160px;
//  left: 220px;
//  color: white;
//  text-align: center;
//  font-size: 3.5em;
//}
//.horoscope-annuel-2014 {
//  width: 250px;
//  position: absolute;
//  bottom: 50px;
//  right: 75px;
//  color: $brand-primary;
//  text-align: center;
//  font-size: 3.2em;
//}
//.horoscope-annuel-2015 {
//  width: 250px;
//  position: absolute;
//  bottom: 175px;
//  right: 75px;
//  color: white;
//  text-align: center;
//  font-size: 3.2em;
//}
//.horoscope-annuel-2016 {
//  width: 220px;
//  position: absolute;
//  bottom: 120px;
//  right: 65px;
//  color: white;
//  text-align: center;
//  font-size: 3em;
//}
//.horoscope-annuel-2017 {
//  width: 220px;
//  position: absolute;
//  bottom: 140px;
//  left: 65px;
//  color: white;
//  text-shadow: 0 0 3px $brand-primary;
//  text-align: center;
//  font-size: 3em;
//}
//.horoscope-automne-2013 {
//  position: absolute;
//  bottom: 45px;
//  left: 480px;
//  color: $brand-primary;
//  font-size: 3em;
//}
//.horoscope-automne-2014,
//.horoscope-automne-2015 {
//  width: 210px;
//  position: absolute;
//  bottom: 135px;
//  left: 445px;
//  color: $brand-primary;
//  font-size: 2.3em;
//  text-align: center;
//}
//.horoscope-automne-2016 {
//  position: absolute;
//  bottom: 40px;
//  left: 330px;
//  color: white;
//  font-size: 2.5em;
//}
//.horoscope-ete-2013 {
//  width: 230px;
//  position: absolute;
//  bottom: 215px;
//  left: 320px;
//  color: $brand-primary;
//  font-size: 2.5em;
//  text-align: center;
//}
//.horoscope-ete-2014 {
//  width: 180px;
//  position: absolute;
//  bottom: 125px;
//  left: 140px;
//  color: white;
//  font-size: 2.5em;
//  text-align: center;
//}
//.horoscope-ete-2015 {
//  position: absolute;
//  top: 280px;
//  left: 55px;
//  color: white;
//  font-size: 3.2em;
//}
//.horoscope-ete-2016 {
//  position: absolute;
//  bottom: 105px;
//  left: 545px;
//  color: white;
//  font-size: 2.6em;
//}
//.horoscope-hebdomadaire {
//  position: absolute;
//  right: 60px;
//  bottom: 50px;
//  color: $brand-primary;
//  font-size: 3.2em;
//}
//.horoscope-hiver-2013{
//  width: 180px;
//  position: absolute;
//  top: 260px;
//  left: 295px;
//  font-size: 2.5em;
//  text-align: center;
//  color: $brand-primary;
//}
//.horoscope-hiver-2014 {
//  width: 180px;
//  position: absolute;
//  top: 100px;
//  left: 295px;
//  font-size: 2.5em;
//  text-align: center;
//  color: white;
//}
//.horoscope-hiver-2015 {
//  width: 180px;
//  position: absolute;
//  top: 100px;
//  left: 295px;
//  font-size: 2.5em;
//  text-align: center;
//  color: $brand-primary;
//}
//.horoscope-hiver-2016 {
//  position: absolute;
//  top: 150px;
//  left: 80px;
//  color: white;
//  font-size: 3.2em;
//}
//.horoscope-mensuel {
//  width: 100%;
//  text-align: center;
//  position: absolute;
//  bottom: 60px;
//  left: 0;
//  right: 0;
//  font-size: 3.2em;
//}
//.horoscope-printemps-2014,
//.horoscope-printemps-2015,
//.horoscope-printemps-2016 {
//  width: 215px;
//  text-align: center;
//  position: absolute;
//  right: 38.5px;
//  bottom: 80px;
//  font-size: 2.9em;
//}
//.horoscope-printemps-2017 {
//  width: 40%;
//  text-align: center;
//  position: absolute;
//  top: 80px;
//  left: 50%;
//  right: 0;
//  color: white;
//}
//.horoscope-quotidien {
//  width: 250px;
//  position: absolute;
//  bottom: 140px;
//  left: 255px;
//  color: white;
//  font-size: 3.2em;
//  text-align: center;
//}
//
//
//.zoom {
//  min-height: 3.7rem;
//  p {
//    text-align:center;
//  }
//  img:hover {
//    -webkit-transform: translate(0px, -10px);
//    -moz-transform: translate(0px, -10px);
//    -ms-transform: translate(0px, -10px);
//    transform: translate(0px, -10px);
//    -webkit-transition-duration: 0.5s;
//    -moz-transition-duration: 0.5s;
//    transition-duration: 0.5s;
//  }
//}
//
///*----------------------HOROSCOPES---------------------*/
//
//// Extra small & Small
//
//// Medium
//@media screen and (min-width: 768px) and (max-width: 991px) {
//  .acc-horoscope{
//    .categories-horoscope{
//      .p-jour{
//        height: 280px;
//      }
//      .p-semaine{
//        height: 280px;
//      }
//      .p-mois{
//        height: 540px;
//      }
//    }
//    .alert-horo{
//      height: 200px;
//    }
//    .bloc-amour{
//      min-height: 290px;
//    }
//  }
//}
//@media screen and (min-width: 992px) and (max-width: 1199px) {
//  .acc-horoscope{
//    .categories-horoscope{
//      .p-mois{
//        height: 440px;
//      }
//    }
//  }
//}
//@media screen and (min-width: 1351px) {
//  .visible-xl {
//    display: block !important;
//  }
//  .hidden-xl {
//    display: none !important;
//  }
//  .acc-horoscope .bloc-sign {
//    height: 100%;
//  }
//  .nav-horoscope {
//    margin-top: 1% !important;
//  }
//}
//@media screen and (max-width: 1350px) {
//  .hidden-xl {
//    display: block !important;
//  }
//  .visible-xl {
//    display: none !important;
//  }
//
//  .horoscope-amour,
//  .horoscope-annuel-2014,
//  .horoscope-annuel-2015,
//  .horoscope-annuel-2016,
//  .horoscope-annuel-2017,
//  .horoscope-automne-2013,
//  .horoscope-automne-2014,
//  .horoscope-automne-2015,
//  .horoscope-automne-2016,
//  .horoscope-ete-2013,
//  .horoscope-ete-2014,
//  .horoscope-ete-2015,
//  .horoscope-ete-2016,
//  .horoscope-hebdomadaire,
//  .horoscope-hiver-2013,
//  .horoscope-hiver-2014,
//  .horoscope-hiver-2015,
//  .horoscope-hiver-2016,
//  .horoscope-mensuel,
//  .horoscope-printemps-2014,
//  .horoscope-printemps-2015,
//  .horoscope-printemps-2016,
//  .horoscope-printemps-2017,
//  .horoscope-quotidien {
//    width: 100%;
//    position: relative;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    right: 0;
//    color: $brand-primary;
//    text-align: center;
//    font-size: 3.5em;
//    text-shadow: none;
//  }
//  .acc-horoscope .bloc-sign{
//    height: 100%;
//  }
//  .bloc-info .img1 img {
//    float: right;
//  }
//  .bloc-info .img2 img {
//    float: left;
//  }
//}
//@media screen and (min-width: 768px) {
//  .nav-horoscope {
//    margin-top: 0!important;
//  }
//}
//@media screen and (max-width: 768px) {
//  .nav-horoscope {
//    float: none;
//    margin-bottom: 6%;
//    margin-left: 0;
//    //margin-top: 21%;
//  }
//  .btn:not(:disabled):not(.disabled) {
//    cursor: pointer;
//    float: none;
//    padding: 1rem;
//  }
//  .bloc-info .img1 img {
//    float: right;
//  }
//  .bloc-info .img2 img {
//    float: left;
//  }
//  .img-polaroid {
//    padding: 0;
//    background-color: #fff;
//    width: 0;
//  }
//  .acc-horoscope .bloc-sign{
//    height: 100%;
//  }
//}
//@media screen and (max-width: 767px) {
//  .acc-horoscope{
//    .categories-horoscope{
//      .p-jour{
//        height: auto;
//      }
//      .p-semaine{
//        height: auto;
//      }
//      .p-mois{
//        height: auto;
//      }
//    }
//    .alert-horo{
//      height: auto;
//    }
//    .bloc-amour{
//      height: auto;
//    }
//  }
//}
//@media screen and (max-width: 576px) {
//  .nav-horoscope {
//    float: none;
//    margin-bottom: -1px;
//    margin-left: 0;
//    //margin-top: 2rem;
//    justify-content: center!important;
//  }
//  .img-polaroid {
//    padding: 0;
//  }
//  .btn:not(:disabled):not(.disabled) {
//    padding: 1rem;
//    cursor: pointer;
//    float: none;
//  }
//}
//@media screen and (max-width: 425px) {
//  //.carousel-item img {
//  //  margin-top: 4rem!important;
//  //}
//  .nav-horoscope {
//    width: 100%;
//  }
//}
///*----------------------HOROSCOPES---------------------*/
//.ete.block .col-sm-4{
//  max-width: 100%!important;
//}
//
//.printemps.block .col-sm-4{
//  max-width: 100%!important;
//}
//@media screen and (min-width: 990px) {
//  .printemps.block-bien-etre {
//    h2 {
//      margin-right: -52%;
//    }
//    .separateur {
//      margin-left: 71%;
//    }
//  }
//
//  .printemps.block-famille {
//    h2 {
//      margin-left: -55%;
//      padding: 28px;
//    }
//    .separateur {
//      margin-left: 25%;
//    }
//  }
//}
//@media screen and (min-width: 576px) {
//  .printemps.block-bien-etre img {
//      margin-top: 9rem;
//  }
//
//  .printemps.block-famille {
//    img {
//      margin-top: 9rem;
//    }
//  }
//
//  .printemps.messieurs img {
//      margin-top: 5rem;
//  }
//}

.acc-horoscope{
  margin-bottom: 20px;
  .horoscope {
    padding-top: 40px;
    margin-top: 30px;
    background-color: $bleu-shadow;
  }
  .description-horoscope{
    padding: 5%;
    background-color: $grey-light;
    margin-bottom: 15px;
    position: relative;
    p{
      margin-top: 30px;
      margin-left: 0;
    }
    img{
      width: 100%;
    }
  }
  .bloc-sign{
    background-color: $grey-light;
    color: $gray;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 2%;
    a{
      color: $brand-primary;
    }
    .mini-horoscope{
      margin-top: 7%;
      a{
        font-weight: bold;
      }
      p{
        padding-right: 5px;
      }
    }
  }
  .titre-bloc{
    background-color: $rose;
    margin-top: 10px;
    h2{
      text-align: center;
      margin: 0;
      font-size: 13px;
      color: $white;
    }
  }
  .nav-horoscope{
    h3{
      text-align: center;
      background-color: $rose;
      padding: 15px;
      color: $white;
      margin-top: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 20px;
      margin-bottom: 10px;
    }
    ul{
      padding: 0;
      margin: 0;
      li{
        list-style-type: none;
        background-color: $brand-primary-10;
        padding: 10px;
        margin-bottom: 5px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 11px;
        font-weight: 500;
        transition-duration: 0.5s;
        line-height: 14px;
        a{
          color: $brand-primary;
          padding: .5rem 1rem;
        }
      }
      li:hover{
        background-color: $brand-primary;
        transition-duration: 0.5s;
        a{
          color: $white;
        }
        a:hover{
          text-decoration: none;
        }
      }
      li.active{
        background-color: $bleu-fonce;
      }
    }
  }
  .categories-horoscope{
    margin-bottom: 20px;
    h2{
      text-align: center;
      font-size: 16px;
      color: $white;
      background-color: $bleu-fonce;
      margin-top: 20px;
      padding: 7px;
      .fa{
        transform: rotate(-4deg);
        font-size: 22px;
        margin-right: 5px;
      }
    }
    .categories-details {
      text-align: justify;
      border: 0;
      padding: 15px;
    }
    .citation {
      .col{
        background-color: $bleu-fonce;
      }
      .categories-details {
        font-size: 22px;
        text-align: center;
        font-family: 'open sans condensed', sans-serif;
        letter-spacing: 1px;
      }
      .fa{
        margin: 0 10px;
      }
    }
    .nombre-de-chance .categories-details, .clin-d-oeil .categories-details {
      text-align: center;
    }
  }
  .lien-detail-horoscope{
    cursor: pointer;
  }
}

/**** Bandeau horoscope ****/
.horoscope {
  background-color: $bleu-fonce;
  padding: 2rem;
  margin-top: 0;
  .zoom {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    min-height: 3.7rem;
    p {
      text-align:center;
    }
    img:hover {
      -webkit-transform: translate(0px, -10px);
      -moz-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      transition-duration: 0.5s;
    }
    .horo-zodiac-blanc {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
    }
  }
  .zoom:hover {
    -webkit-transform: scale(1.09);
    transform: scale(1.09);
  }
}