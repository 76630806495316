/*********** FOOTER ***********/
footer{
  display: block;
  padding: 0;
  p{
    line-height: 2em;
  }
  .first-footer{
    padding: 30px 0 30px 0;
    background-color: $white-800;
    h3{
      margin-top: 0;
      font-family: 'open sans condensed', sans-serif;
      font-weight: bold;
    }
    .container-avis, .container-articles{
      overflow-x: hidden;
    }
    .articles-recents{
      padding-bottom: 30px;
      .article{
        border-bottom: solid 1px $brand-primary;
        padding: 15px 0;
        text-align: left;
        i.fa.fa-chevron-right{
          color: $bleu-fonce;
        }
        a{
          p{
            display: inline;
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
        a:hover{
          text-decoration: none;
        }
      }
    }
    .avis-recents{
      margin-top: 0;
      padding-bottom: 30px;
      h6{
        margin: 0;
        font-size: 13px;
      }
      .fa{
        color:$brand-primary;
      }
      .avis{
        border-bottom: solid 1px $brand-primary;
        padding: 15px 0;
        text-align: left;
        cursor: pointer;
        i.fa.fa-star.yellow{
          color: $bleu-fonce;
        }
        p{
          font-size: 11px;
          margin-bottom: 0;
        }
      }
      a{
        color: #000;
      }
    }
    .reseaux-sociaux .fab:hover{
      color: $black-color;
    }
    .disclaimer{
      margin-top: 50px;
      p{
        font-size: 10px;
      }
    }
  }
  .second-footer{
    background-color: $bleu-fonce;
    padding: 25px 0;
  }
  .mentions-footer{
    padding: 45px 0;
    text-align: center;
    color: white;
    background-color: #444;
    p{
      font-size: 11px;
      margin: 0;
    }
    .btn-primary-dark{
      font-size: .875rem;
      line-height: 1.33333;
      border-radius: .2rem;
    }
  }
}