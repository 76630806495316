//.astrologie{
//  .form-control {
//    width: auto !important;
//  }
//  .acc-horoscope{
//    margin-top:5rem;
//  }
//  h3{
//    margin-top: 30px;
//    color: $bleu-fonce;
//  }
//  .bloc-astro{
//    border: solid 3px #f3f3f3;
//    padding: 20px;
//    margin-top: 18px;
//    :hover {
//      cursor: pointer;
//    }
//    h4{
//      text-align: center;
//    }
//    .img-astro{
//      max-width: 200px;
//      margin-left: auto;
//      margin-right: auto;
//      display: block;
//    }
//    p{
//      margin-top: 20px;
//      text-align: justify;
//    }
//    .karmique img{
//      width: 100%;
//      float: right;
//    }
//  }
//  .text-astro{
//    margin-top: 30px;
//    margin-bottom: 50px;
//  }
//  //.bloc-jeu{
//  //	height: 260px;
//  //}
//  .bloc-test{
//    height: auto;
//  }
//  .btn-astro {
//    width: 30%;
//    min-width: 100%;
//    background-color: #a0d9d2;
//    margin: 20px auto;
//  }
//  .astro-love { border-right: 2px solid rgb(227,98,122); }
//}
//
///*--------------------ASTROLOGIE ASCENDANT---------------------*/
//.astrologie.ascendant{
//  img{
//    width: auto;
//    margin-top: 11%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
///*----------ASTROLOGIE COMPTABILITE AMOUREUSE---------*/
//.astrologie.compta_amoureux{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro {
//    min-height: 182px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
//
//
///*-------------------ASTROLOGIE INDIVIDUELLE---------*/
//.astrologie.individuelle{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*-------------------ASTROLOGIE MONDIALE---------*/
//.astrologie.mondiale{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*-------------------ASTROLOGIE JOUR SEMAINE MOIS---------*/
//.astrologie.jma{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*-------------------ASTROLOGIE CHINOIS---------*/
//.astrologie.chinois{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*-------------------ASTROLOGIE GRATUIT---------*/
//.astrologie.gratuit{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*-------------------ASTROLOGIE KARMIQUE---------*/
//.astrologie.karmique{
//  img{
//    width: auto;
//    margin-top: 5%;
//  }
//  .bloc-astro{
//    min-height: 131px;
//    margin-top: 0px;
//  }
//  .acc-horoscope{
//    margin-top: 3rem;
//  }
//}
//
///*----------------------RESPONSIVE---------------------*/
//
//.astrologie{
//  .bloc-astro{
//    @media screen and (min-width: 992px){
//      min-height: auto;
//      margin-top: 84px;
//      }
//    @media screen and (min-width: 768px) and (max-width: 991px){
//      height: auto;
//    }
//    @media screen and (max-width: 767px){
//      height: auto;
//      .carousel-inner {
//        position: relative;
//        width: 100%;
//        overflow: hidden;
//        margin-top: 0rem;
//      }
//    }
//  }
//
//  @media screen and (max-width: 767px){
//    .cadre-grey-hindoue {
//      background-color: $brand-primary-10;
//      padding: 3%;
//    }
//  }
//
//  @media (max-width: 576px){
//    .numeros p {
//      font-weight: bold;
//      margin-left: 14rem;
//      margin-bottom: -18px;
//      margin-top: -12px;
//    }
//    .form_anniv form {
//      margin-left: 28%;
//    }
//  }
//}
//
//@media screen and (max-width: 768px) {
//  .astrologie {
//    .astr_hindou {
//      .bloc-astro {
//        margin-top: -10rem;
//
//        .ascendant {
//          height: 225px;
//          margin-top: 0px;
//        }
//      }
//    }
//  }
//  .astrologie.compta_amoureux img {
//    width: 100%;
//  }
//  .astrologie.individuelle img {
//        width: 83%;
//  }
//}
//
//
//@media screen and (min-width: 1024px) {
//  .astrologie {
//    .astr_hindou.offset-4 {
//        margin-left: 0;
//        margin-left: 0;
//      }
//      #app_birthday_year {
//        margin-top: 40px;
//        margin-bottom: 20px;
//      }
//      #app_birthday{
//        margin-left: 30%;
//      }
//    }
//}
//
//@media screen and (max-width: 320px) {
//  .astrologie.individuelle img {
//    width: 100%;
//  }
//  .astrologie .acc-horoscope {
//    margin-top: 0;
//  }
//}
//
//@media screen and (max-width: 425px) {
//    .astrologie {
//      .individuelle img {
//        width: 100%;
//        margin-bottom: 1rem;
//      }
//      #app_birthday_year {
//        width: 100% !important;
//      }
//      #app_birthday {
//        width: 100%;
//        margin: 0 auto;
//      }
//    }
//}
//  //#hindou .form-control {
//  //  width: 100% !important;
//  //}
///*----------------------result astro hindoue---------------------*/
//.resultat-astrologie-hindoue{
//  .resultat-signe {
//    .img-signe {
//      position: absolute;
//      right: 0;
//    }
//  }
//  .center-block {
//    display: block;
//    margin-right: auto;
//    margin-left: auto;
//  }
//}
//
///*----------------------result astro tibitaine---------------------*/
//
//
//.resultat-astrologie-tibetaine{
//  .resultat-signe{
//    height: 237px;
//    position: relative;
//    .titre-resultat{
//      position: absolute;
//      left: 160px;
//    }
//  }
//
//  p{
//    margin: 0;
//  }
//
//  /*----------result mobile----------*/
//  .center-block {
//    display: block;
//    margin-right: auto;
//    margin-left: auto;
//  }
//
//  .preface{
//    background-color: $brand-primary-10;
//    padding: 40px;
//    .img-responsive{
//      width: 100%;
//    }
//  }
//  .bloc-resultat{
//    margin-top: 40px;
//    h2{
//      color: $bleu-fonce;
//      margin-bottom: 20px;
//    }
//  }
//
//  .signes-compatibles{
//    width: 690px;
//    height: 520px;
//    position: relative;
//    background: url("../img/astrologie/tibet/bg_compat.jpg") no-repeat left top;
//    div{
//      position: absolute;
//      width: 245px;
//      height: 254px;
//    }
//    .compatibilite{
//      top: 0;
//      left: 98px;
//    }
//    .amitie{
//      top: 0;
//      right: 98px;
//    }
//    .rivalite{
//      bottom: 10px;
//      left: 98px;
//    }
//    .affrontement{
//      bottom: 10px;
//      right: 98px;
//    }
//  }
//  .signes-comp-mobile{
//    .bloc-comp{
//      margin-top: 5px;
//      margin-bottom: 15px;
//    }
//  }
//  .fiche-identite{
//    h4{
//      font-size: 15px;
//      margin-bottom: 3px;
//    }
//    .bloc-identite{
//      width: 225px;
//      height: 120px;
//      margin-bottom: 30px;
//      background-repeat: no-repeat;
//      background-position: left top;
//      position: relative;
//      opacity: 0.7;
//      transition-duration: 0.5s;
//      p{
//        color: black;
//        padding: 8px;
//        width: 100%;
//        background-color: white;
//        opacity: 0.8;
//        text-align: center;
//        text-transform: uppercase;
//        font-weight: 600;
//        font-size: 12px;
//        letter-spacing: 1px;
//        position: absolute;
//        bottom: 0;
//        transition-duration: 0.5s;
//        line-height: 16px;
//      }
//    }
//    /*-- SAISONS --*/
//
//    .le-printemps{
//      background-image: url("../img/astrologie/tibet/fiche-identite/printemps.jpg");
//    }
//    .l-automne{
//      background-image: url("../img/astrologie/tibet/fiche-identite/automne.jpg");
//    }
//    .l-hiver{
//      background-image: url("../img/astrologie/tibet/fiche-identite/hiver.jpg");
//    }
//    .l-ete{
//      background-image: url("../img/astrologie/tibet/fiche-identite/ete.jpg");
//    }
//
//    /*-- CHIFFRES --*/
//
//    .chiffres{
//      background-image: url("../img/astrologie/tibet/fiche-identite/chiffres.jpg");
//    }
//
//    /*-- KARMA --*/
//
//    .activites-karmiques{
//      background-image: url("../img/astrologie/tibet/fiche-identite/activite-karmique.jpg");
//    }
//
//    /*-- PROTECTION --*/
//
//    .le-lama-vert{
//      background-image: url("../img/astrologie/tibet/fiche-identite/lama-vert.jpg");
//    }
//    .le-lama-jaune{
//      background-image: url("../img/astrologie/tibet/fiche-identite/lama-jaune.jpg");
//    }
//    .le-lama-rouge,
//    .lama-rouge{
//      background-image: url("../img/astrologie/tibet/fiche-identite/lama-rouge.jpg");
//    }
//    .le-lama-noir{
//      background-image: url("../img/astrologie/tibet/fiche-identite/lama-noir.jpg");
//    }
//
//    /*-- ELEMENTS --*/
//
//    .bois{
//      background-image: url("../img/astrologie/tibet/fiche-identite/bois.jpg");
//    }
//    .fer{
//      background-image: url("../img/astrologie/tibet/fiche-identite/metal.jpg");
//    }
//    .eau{
//      background-image: url("../img/astrologie/tibet/fiche-identite/eau.jpg");
//    }
//    .feu{
//      background-image: url("../img/astrologie/tibet/fiche-identite/feu.jpg");
//    }
//    .embrasement{
//      background-image: url("../img/astrologie/tibet/fiche-identite/embrasement.jpg");
//    }
//    .terre{
//      background-image: url("../img/astrologie/tibet/fiche-identite/terre.jpg");
//    }
//    .metal{
//      background-image: url("../img/astrologie/tibet/fiche-identite/metal.jpg");
//    }
//
//    /*-- SYMBOLE --*/
//
//    .arbre{
//      background-image: url("../img/astrologie/tibet/fiche-identite/arbre.jpg");
//    }
//    .chene{
//      background-image: url("../img/astrologie/tibet/fiche-identite/chene.jpg");
//    }
//    .sabre{
//      background-image: url("../img/astrologie/tibet/fiche-identite/sabre.jpg");
//    }
//    .pluie{
//      background-image: url("../img/astrologie/tibet/fiche-identite/pluie.jpg");
//    }
//    .flammes{
//      background-image: url("../img/astrologie/tibet/fiche-identite/flammes.jpg");
//    }
//    .etincelle{
//      background-image: url("../img/astrologie/tibet/fiche-identite/etincelle.jpg");
//    }
//    .sapin{
//      background-image: url("../img/astrologie/tibet/fiche-identite/sapin.jpg");
//    }
//    .vague{
//      background-image: url("../img/astrologie/tibet/fiche-identite/vague.jpg");
//    }
//    .riviere{
//      background-image: url("../img/astrologie/tibet/fiche-identite/riviere.jpg");
//    }
//    .pierre{
//      background-image: url("../img/astrologie/tibet/fiche-identite/pierre.jpg");
//    }
//    .epee{
//      background-image: url("../img/astrologie/tibet/fiche-identite/epee.jpg");
//    }
//
//    /*-- COULEURS --*/
//
//    .bleu {
//      //background-image:none !important;
//      background-color:#1fa7e7;
//    }
//    .turquoise {
//      //background-image:none !important;
//      background-color:#14f6e9;
//    }
//    .blanc {
//      //background-image:none !important;
//      background-color:#ffffff;
//      border: 1px solid #ECECEC;
//      p{
//        background-color: #ECECEC;
//      }
//    }
//    .bleu-azur {
//      background-color:#007FFF;
//    }
//    .vert-malachite {
//      background-color:#1fa055;
//    }
//    .vert {
//      background-color:#97bf0d;
//    }
//    .emeraude {
//      background-color:#01D758;
//    }
//    .opaque {
//      background-color:#000000;
//    }
//    .cobalt {
//      background-color:#0047AB;
//    }
//    .rouge {
//      background-color:#cc1515;
//    }
//    .ecarlate {
//      background-color:#ff0000;
//    }
//
//    /*-- DIRECTION --*/
//
//    .direction{
//      background-image: url("../img/astrologie/tibet/fiche-identite/direction.jpg");
//    }
//
//    /*-- PERIODES --*/
//
//    .l-aube{
//      background-image: url("../img/astrologie/tibet/fiche-identite/aube.jpg");
//    }
//    .la-soiree{
//      background-image: url("../img/astrologie/tibet/fiche-identite/soiree.jpg");
//    }
//    .la-nuit{
//      background-image: url("../img/astrologie/tibet/fiche-identite/nuit.jpg");
//    }
//    .le-midi{
//      background-image: url("../img/astrologie/tibet/fiche-identite/midi.jpg");
//    }
//
//    /*-- QUALITES --*/
//
//    .qualite{
//      background-image: url("../img/astrologie/tibet/fiche-identite/qualite.jpg");
//    }
//
//    /*-- ORGANE --*/
//
//    .organe{
//      background-image: url("../img/astrologie/tibet/fiche-identite/organe.jpg");
//    }
//
//    /*-- PLANETE --*/
//
//    .jupiter{
//      background-image: url("../img/astrologie/tibet/fiche-identite/jupiter.jpg");
//    }
//    .venus{
//      background-image: url("../img/astrologie/tibet/fiche-identite/venus.jpg");
//    }
//    .mercure-lune{
//      background-image: url("../img/astrologie/tibet/fiche-identite/mercure-lune.jpg");
//    }
//    .soleil-mars{
//      background-image: url("../img/astrologie/tibet/fiche-identite/soleil-mars.jpg");
//    }
//    .saturne{
//      background-image: url("../img/astrologie/tibet/fiche-identite/saturne.jpg");
//    }
//
//    .bloc-identite:hover{
//      opacity: 1;
//      transition-duration: 0.5s;
//      p{
//        transform: translateY(-30px);
//        transition-duration: 0.5s;
//      }
//    }
//  }
//}
//
//.nav-menu.astrologie{
//    margin-top: 21%;
//}
//

.astrologie{
  h3{
    margin-top: 30px;
    color: $bleu-fonce;
  }
  .bloc-astro{
    border: solid 3px $gray-600;
    padding: 20px;
    margin-top: 18px;
    :hover {
      cursor: pointer;
    }
    h4{
      text-align: center;
    }
    p{
      margin-top: 20px;
      text-align: justify;
    }
  }
  .text-astro{
    margin-top: 30px;
  }
  #app_birthday_year, #app_birthday{
    height: calc(1.5em + 0.75rem + 2px);
    color: #aaa;
    border: none;
    width: 100%;
  }
  .btn-astro {
    background-color: $bleu-shadow;
    margin: 20px auto;
    color: $white;
  }
  .btn-astro:hover {
    background-color: $bleu-fonce;
  }
}
.resultat-astrologie-hindoue, .resultat-astrologie-tibetaine{
  .preface{
    background-color: $brand-primary-10;
    padding: 40px;
  }
}
.resultat-astrologie-hindoue{
  .resultat-signe {
    .img-signe {
      position: absolute;
      right: 0;
      width: 24%;
    }
    .astro-love {
      border-right: 2px solid rgb(227,98,122);
    }
  }
}
.resultat-astrologie-tibetaine{
  .resultat-signe{
    height: 237px;
    position: relative;
    .titre-resultat{
      position: absolute;
      left: 160px;
    }
  }
  .bloc-resultat {
    margin-top: 40px;
    h2 {
      color: $bleu-fonce;
      margin-bottom: 20px;
      font-size: 26px;
    }
    .signes-compatibles {
      width: 690px;
      height: 520px;
      position: relative;
      background: url("../img/astrologie/tibet/bg_compat.jpg") no-repeat left top;

      div {
        position: absolute;
        width: 245px;
        height: 254px;
      }

      .compatibilite {
        top: 0;
        left: 98px;
      }

      .amitie {
        top: 0;
        right: 98px;
      }

      .rivalite {
        bottom: 10px;
        left: 98px;
      }

      .affrontement {
        bottom: 10px;
        right: 98px;
      }
    }
    .signes-comp-mobile {
      .bloc-comp {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }
  }
  .fiche-identite{
    h4{
      font-size: 15px;
      margin-bottom: 5px;
    }
    .bloc-identite{
      width: 225px;
      height: 120px;
      margin-bottom: 30px;
      background-repeat: no-repeat;
      background-position: left top;
      position: relative;
      opacity: 0.7;
      transition-duration: 0.5s;;
      p{
        color: $black-color;
        padding: 8px;
        width: 100%;
        background-color: $white;
        opacity: 0.8;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0;
        transition-duration: 0.5s;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
    .bloc-identite:hover{
      opacity: 1;
      transition-duration: 0.5s;
      p{
        transform: translateY(-30px);
        transition-duration: 0.5s;
      }
    }
    /*-- SAISONS --*/
    .le-printemps{
      background-image: url("../img/astrologie/tibet/fiche-identite/printemps.jpg");
    }
    .l-automne{
      background-image: url("../img/astrologie/tibet/fiche-identite/automne.jpg");
    }
    .l-hiver{
      background-image: url("../img/astrologie/tibet/fiche-identite/hiver.jpg");
    }
    .l-ete{
      background-image: url("../img/astrologie/tibet/fiche-identite/ete.jpg");
    }
    /*-- CHIFFRES --*/
    .chiffres{
      background-image: url("../img/astrologie/tibet/fiche-identite/chiffres.jpg");
    }

    /*-- KARMA --*/
    .activites-karmiques{
      background-image: url("../img/astrologie/tibet/fiche-identite/activite-karmique.jpg");
    }
    /*-- PROTECTION --*/
    .le-lama-vert{
      background-image: url("../img/astrologie/tibet/fiche-identite/lama-vert.jpg");
    }
    .le-lama-jaune{
      background-image: url("../img/astrologie/tibet/fiche-identite/lama-jaune.jpg");
    }
    .le-lama-rouge, .lama-rouge{
      background-image: url("../img/astrologie/tibet/fiche-identite/lama-rouge.jpg");
    }
    .le-lama-noir{
      background-image: url("../img/astrologie/tibet/fiche-identite/lama-noir.jpg");
    }
    /*-- ELEMENTS --*/
    .bois{
      background-image: url("../img/astrologie/tibet/fiche-identite/bois.jpg");
    }
    .fer{
      background-image: url("../img/astrologie/tibet/fiche-identite/metal.jpg");
    }
    .eau{
      background-image: url("../img/astrologie/tibet/fiche-identite/eau.jpg");
    }
    .feu{
      background-image: url("../img/astrologie/tibet/fiche-identite/feu.jpg");
    }
    .embrasement{
      background-image: url("../img/astrologie/tibet/fiche-identite/embrasement.jpg");
    }
    .terre{
      background-image: url("../img/astrologie/tibet/fiche-identite/terre.jpg");
    }
    .metal{
      background-image: url("../img/astrologie/tibet/fiche-identite/metal.jpg");
    }
    /*-- SYMBOLE --*/
    .arbre{
      background-image: url("../img/astrologie/tibet/fiche-identite/arbre.jpg");
    }
    .chene{
      background-image: url("../img/astrologie/tibet/fiche-identite/chene.jpg");
    }
    .sabre{
      background-image: url("../img/astrologie/tibet/fiche-identite/sabre.jpg");
    }
    .pluie{
      background-image: url("../img/astrologie/tibet/fiche-identite/pluie.jpg");
    }
    .flammes{
      background-image: url("../img/astrologie/tibet/fiche-identite/flammes.jpg");
    }
    .etincelle{
      background-image: url("../img/astrologie/tibet/fiche-identite/etincelle.jpg");
    }
    .sapin{
      background-image: url("../img/astrologie/tibet/fiche-identite/sapin.jpg");
    }
    .vague{
      background-image: url("../img/astrologie/tibet/fiche-identite/vague.jpg");
    }
    .riviere{
      background-image: url("../img/astrologie/tibet/fiche-identite/riviere.jpg");
    }
    .pierre{
      background-image: url("../img/astrologie/tibet/fiche-identite/pierre.jpg");
    }
    .epee{
      background-image: url("../img/astrologie/tibet/fiche-identite/epee.jpg");
    }

    /*-- COULEURS --*/
    .bleu {
      background-color:#1fa7e7;
    }
    .turquoise {
      background-color:#14f6e9;
    }
    .blanc {
      background-color:#ffffff;
      border: 1px solid #ECECEC;
      p{
        background-color: #ECECEC;
      }
    }
    .bleu-azur {
      background-color:#007FFF;
    }
    .vert-malachite {
      background-color:#1fa055;
    }
    .vert {
      background-color:#97bf0d;
    }
    .emeraude {
      background-color:#01D758;
    }
    .opaque {
      background-color:#000000;
    }
    .cobalt {
      background-color:#0047AB;
    }
    .rouge {
      background-color:#cc1515;
    }
    .ecarlate {
      background-color:#ff0000;
    }
    /*-- DIRECTION --*/
    .direction{
      background-image: url("../img/astrologie/tibet/fiche-identite/direction.jpg");
    }
    /*-- PERIODES --*/
    .l-aube{
      background-image: url("../img/astrologie/tibet/fiche-identite/aube.jpg");
    }
    .la-soiree{
      background-image: url("../img/astrologie/tibet/fiche-identite/soiree.jpg");
    }
    .la-nuit{
      background-image: url("../img/astrologie/tibet/fiche-identite/nuit.jpg");
    }
    .le-midi{
      background-image: url("../img/astrologie/tibet/fiche-identite/midi.jpg");
    }
    /*-- QUALITES --*/
    .qualite{
      background-image: url("../img/astrologie/tibet/fiche-identite/qualite.jpg");
    }
    /*-- ORGANE --*/
    .organe{
      background-image: url("../img/astrologie/tibet/fiche-identite/organe.jpg");
    }
    /*-- PLANETE --*/
    .jupiter{
      background-image: url("../img/astrologie/tibet/fiche-identite/jupiter.jpg");
    }
    .venus{
      background-image: url("../img/astrologie/tibet/fiche-identite/venus.jpg");
    }
    .mercure-lune{
      background-image: url("../img/astrologie/tibet/fiche-identite/mercure-lune.jpg");
    }
    .soleil-mars{
      background-image: url("../img/astrologie/tibet/fiche-identite/soleil-mars.jpg");
    }
    .saturne{
      background-image: url("../img/astrologie/tibet/fiche-identite/saturne.jpg");
    }
  }
}
.acc-menu .nav-menu ul li:hover {
  background-color: $black-color;
  transition-duration: 0.5s;
  a{
    color: $white;
  }
}