/**** Tarot ****/
#detailsTarot{
  .tarot_container {
    position: relative;
    min-height: 850px;
    width: 100%;
    max-width: 960px;
    margin: 0 auto 50px;
    overflow-x: hidden;
    overflow-y: visible;
    [class*="sequence"] {
      height: auto;
      width: 100%;
      max-width: 960px;
      top: 0;
      transition: left 2s;
      //min-height: 540px;
      z-index: 0;
    }
    .marginTarot{
      margin-top: 6rem;
    }
    #cartesdos_wrapper {
      text-align: center;
      .imgTarot {
        width: 25%;
      }
      .imgTarot:hover{
        cursor: pointer;
      }
    }
    #resultCard{
      .emplacementCarte{
        display: table-cell;
        vertical-align: middle;
        width: 100px;
        height: 160px;
        text-align: center;
      }
      .borderTarot{
        border: 2px dashed $white;
        border-radius: 5px;
      }
    }
  }
  .tarotForm{
    display: none;
    .card {
      .imgRep{
        width: 70%;
      }
      .card_name {
        font-family: "caliban";
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        padding: 0 15px;
      }
    }
  }
}

#listTarot{
  .accesTirage{
    width: 100%;
  }
  .btn-primary{
    border-top-left-radius: initial;
    border-top-right-radius: initial;
    &:focus{
      background-color: #5c8882;
      border-color: #5c88820d;
    }
  }
}