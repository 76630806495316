/*====================== FORMULAIRES ======================*/

form {
  label{
    color: $grey;
    text-transform: uppercase;
    &.required {
      &:before {
        content: "* ";
        color: $rose;
      }
    }
  }
  .form-check-input:checked {
    background-color: $bleu-fonce;
    border-color: $bleu-fonce;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
  .form-control:focus{
    box-shadow: none;
  }
  .form-control, .form-control::placeholder{
    border: none;
    color: $grey;
  }
  .form-control:read-only, .form-control {
    background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
  }
  .is-focused .form-control {
    background-image: linear-gradient(to top, #FA5F6D 2px, rgba(0, 123, 255, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
    background-size: 100% 100%,100% 100%;
  }
  #cgu_cgv, #reinitialise a{
    color: $bleu-fonce;
    &:hover {
      font-weight: 700;
    }
  }
  legend{
    display: none;
  }
  .alert-danger, .invalid-feedback .form-error-message{
    color: var(--bs-danger);
    font-weight: bold;
  }
}

#register, #editShow, #contact, #recrutement{
  .gender .col-sm-2{
    display: none;
  }
}
#app_registration_gender, #app_profile_gender, #contact_form_civility, #recrutement_form_civility, #avis_form_recommended, #avis_form_consultation_type {
  display: inline-flex;
}

#app_registration_gender #app_registration_gender_0, #app_profile_gender #app_profile_gender_0, #contact_form_civility #contact_form_civility_0, #recrutement_form_civility #recrutement_form_civility_0, #avis_form_recommended #avis_form_recommended_0, #avis_form_consultation_type #avis_form_consultation_type_0{
  margin-right: 5px;
}
#app_registration_gender #app_registration_gender_1, #app_profile_gender #app_profile_gender_1, #contact_form_civility #contact_form_civility_1, #recrutement_form_civility #recrutement_form_civility_1, #avis_form_recommended #avis_form_recommended_1, #avis_form_consultation_type #avis_form_consultation_type_1{
  margin-right: 5px;
  margin-left: 0;
}

#app_registration_telephone_number, #app_profile_telephone_number, #contact_form_telephone_number, #recrutement_form_telephone_number, #voyance_email_form_phone_number_number{
  margin-left: -4px;
}
#editShow {
  .bmd-form-group {
    display: contents;
  }
}

#app_profile_emailHoroscopeTypes, #app_registration_emailHoroscopeTypes {
  label {
    margin-right: 10px;
  }
}
#app_registration_emailHoroscopeTypes label{
  text-transform: capitalize;
}
#show{
  .deconnection {
    font-weight: 900;
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.33333;
    border-radius: .2rem;
    text-transform: uppercase;
  }
  .profil_show ul li a.editer {
    color: $bleu-fonce;
  }
  .compteInvalid {
    white-space: normal;
    word-wrap: break-word;
    font-size: 14px
  }
}

.puce_katleen.puce2 li {
  list-style-type: none;
  line-height: 26px;
}
.puce_katleen li::before {
  content: "▸";
  margin-right: 10px;
  color: $rose;
  font-size: 22px;
  list-style-type: none;
}

#contact .bouton_contact .btn{
  font-weight: 900;
}

.form-control.is-invalid {
  border-bottom: 1px solid $gray-400;
}