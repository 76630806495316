$white: #fff;
$white-500: #e5e5e5;
$white-800: #f8f8f8;

/*rose base*/
$rose: #fa5f6d;
$rose-dark: #d75762;
$color-prive: #DF4E6B;
$rose-clair: #FF7A86;

$bleu-shadow: #A0D9D2;
$bleu-fonce: #7AB8B0;
$bleu-400: #17a2b8;
$bleu-800: #1596aa;

/*Noir*/
$black-color: #000000;
$brand-primary: rgba(0, 0, 0, 1);
$brand-primary-90: rgba(0, 0, 0, 0.9);
$brand-primary-80: rgba(0, 0, 0, 0.8);
$brand-primary-70: rgba(0, 0, 0, 0.7);
$brand-primary-60: rgba(0, 0, 0, 0.6);
$brand-primary-50: rgba(0, 0, 0, 0.5);
$brand-primary-40: rgba(0, 0, 0, 0.4);
$brand-primary-30: rgba(0, 0, 0, 0.3);
$brand-primary-20: rgba(0, 0, 0, 0.2);
$brand-primary-10: rgba(0, 0, 0, 0.1);
$brand-primary-dark: rgba(91, 85, 133, 1);
$brand-primary-light: rgba(91, 85, 133, 1);
/*gris*/
$grey-light: #f6f6f6;
$grey: #9e9e9e;
$gray: #555;
$gray-400: #ced4da;
$gray-600: #f3f3f3;
$gray-800: #e9ecef;

$danger: #dc3545;
$green: #4caf50;
$orange: rgba(245, 165, 3, 1);
$orange-200: #ffc107;
$warning: #fd7e14;
$color-audio: #a51078;
$carousel-control-color: $color-audio;


$theme-colors: (
        "primary": $bleu-fonce,
        "secondary":  $gray,
        "success":    $green,
        "info":       $bleu-400,
        "warning":    $warning,
        "danger":     $danger,
        "dark":       $black-color,
        "audiotel":   $color-audio,
        "default": $gray
);


