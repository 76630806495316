.infos-dispo, .infos-dispo-privee {
  background-color: $green;
}

.infos-consult {
  background-color: $orange;
}

.infos-dispo-audiotel, .infos-dispo-audio {
  background-color: $color-audio;
}

.infos-indispo {
  background-color: $grey;
}

.nosVoyants .fiche-voyant .lien-detail img.nouveau {
  object-position: left top;
  width: 52px;
  position: absolute;
  z-index: 1;
}
.nouveauVoyant {
  object-position: left top;
  width: 52px!important;
  position: absolute;
  z-index: 1;
}
.fiche-voyant {
  > .row {
    margin: 0;
  }

  .contact-voyant:hover {
    background-color: $rose-clair;

    a {
      color: $brand-primary;
    }
  }

  .bloc-rdv {
    background-color: $bleu-fonce;
  }

  .bloc-rdv-audiotel {
    background-color: $bleu-fonce;
  }

  .bloc-rdv:hover {
    background-color: $bleu-fonce;

    a {
      color: $brand-primary;
    }

    a:active {
      text-decoration: none;
    }
  }
}

.bloc-voyants .fiche-voyant .lien-detail .img-voyant-list {
  min-height: 110px;

  img.nouveau {
    object-position: left top;
    width: 52px;
    position: absolute;
  }
}

.liste-voyants {
  .fiche-voyant {

    .border-fiche-dispo, .border-fiche-indispo, .border-fiche-consult {
      border: none;
      background-color: $gray-600;
    }

    h5 {
      margin-top: 10px;
    }

    .lien-detail {
      cursor: pointer;
    }

    .infos {
      color: $white;

      .dispo {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .portrait {
      margin-top: 15px;
    }

    .logos-katleen {
      .mini-logo {
        display: inline-block;
        float: left;
      }
    }

    a, span {
      color: $white;
    }

    .note {
      margin-left: 10px;
    }

    .bloc-rdv {
      cursor: pointer;
    }

    .bloc-rdv, .contact-voyant, .contact-audio {
      padding-top: 10px;
      padding-bottom: 10px;

      .prise-rdv {
        text-align: center;
        margin-bottom: 0;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;

        i.fa.fa-phone {
          margin-right: 5px;
        }
      }
    }

    .contact-voyant {
      p {
        margin: 0;
      }
    }
  }

  .slick-slide img {
    width: auto;
  }
}

#fiche-perso {
  .cardImgVoyant {
    //object-fit: cover;
    //top: 0;
    //position: absolute;
    //left: 0;
    //width: 100%;
    //height: 100%;

  }

  .cardImgVoyant-container {
    overflow: hidden;
    position: relative;
    aspect-ratio: 3 / 4;
    max-width: 227px;
  }

  .contain-nav {
    background-color: $grey-light;
  }

  .nav-pills .nav-item {
    .nav-link {
      line-height: 24px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      min-width: 100px;
      text-align: center;
      color: #555;
      transition: all .3s;
      border-radius: 0;
      padding: 10px 15px;
    }

    .bg_color_3:hover, .bg_color_3.active {
      background-color: $bleu-fonce;
    }

    .bg_color_1:hover, .bg_color_1.active {
      background-color: $rose;
    }

    .bg_color_2:hover, .bg_color_2.active {
      background-color: $color-audio;
    }

    .nav-link.active {
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 123, 255, 0.6);
    }
  }

  .header-fiche .disponibilite {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $white;
    line-height: 1.5em;
  }

  .fiche-voyant {
    .header-fiche {
      .presentation-voyant {
        h2 {
          font-size: 40px;
        }

        h3 {
          color: $rose;
          font-family: "Open Sans", sans-serif;
          font-style: italic;
          text-transform: lowercase;
          font-weight: 400;
        }

        .note-voyant {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0 5px 0;
          color: rgba(0, 0, 0, 0.8);

          .yellow {
            color: $bleu-fonce;
          }
        }
      }

      .avis {
        .btn-avis {
          background-color: $rose;
          color: $white;
          transition-duration: 0.5s;
          padding: 10px;
          display: inline-block;
          border-radius: 3px;
          margin-bottom: 3%;
          margin-top: 0;
          text-align: center;

          a {
            color: $white;
            font-size: 15px;
          }
        }

        .btn-avis:hover {
          background-color: rgba(0, 0, 0, 0.7);
          transition-duration: 0.5s;
          text-decoration: none;
        }
      }

      img.nouveau {
        width: 80px;
        margin-top: -28rem;
      }

      .supports, .certifs {
        h3 {
          color: $brand-primary;
          margin-bottom: 0;
          padding-left: 0;
          margin-top: 20px;
          font-size: 24px;
          line-height: 26px;
          font-family: 'Open Sans Condensed', sans-serif;
          font-weight: bold;
          font-style: italic;
          text-transform: lowercase;
        }
      }

      .supports {
        h4 {
          margin-top: 0;
          font-weight: 300;
          letter-spacing: 0;
          text-transform: lowercase;
          font-size: 16px;
          color: $brand-primary-80;
        }
      }

      .listing-mot {
        margin-left: 10px;
        display: inline-block;
        padding: 5px 10px;
        margin-bottom: 10px;
        color: $black-color;
        border: solid 1px $black-color;
        font-size: 11px;
        margin-top: 20px;
        width: 80%;

        .mot-cle {
          margin-left: 10px;
          display: inline-block;
          padding: 5px 10px;
          margin-bottom: 10px;
          color: $brand-primary;
          border: solid 1px $brand-primary;
          font-size: 11px;
        }

        p {
          text-align: center;
          margin-top: 4%;
        }
      }
    }

    .boutons-voyant {
      .btn-voyant {
        font-size: 12px;
        padding: 1rem;
        text-align: center;
        background-color: $bleu-fonce;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        border-right: solid 3px $white;
        border-bottom: solid 3px white;

        a {
          color: $white;
        }

        a.numero-prive {
          font-size: 14px;
        }

        #typeTelDetail {
          margin: 3px 0 0;
          width: 13rem;
        }
      }

      .btn-voyant:hover {
        background-color: $bleu-fonce;
        color: $brand-primary;

        a {
          color: $brand-primary;
        }
      }

      #modal-alerte-sms form {
        #sms input {
          font-size: initial;
          padding: 0;
        }

        #sms:before {
          content: "\002709";
          font-size: 20px;
          display: inline-block;
          padding: 0 5px;
          margin: -15px 0 -5px 0;
          top: 3px;
          position: relative;
        }

        #alerte_sms_mobile_number {
          margin-left: -4px;
        }
      }

      #modal-rappel-secretariat form {
        #appel input.btn {
          font-size: initial;
          padding: 0;
        }

        #appel:before {
          content: "\01F57D";
          font-size: 24px;
          display: inline-block;
          -webkit-transform: rotate(40deg);
          -moz-transform: rotate(40deg);
          -o-transform: rotate(40deg);
          transform: rotate(40deg);
          padding: 0 5px;
          margin: -15px 0 -5px 0;
          top: 3px;
          position: relative;
        }
      }
    }

    .description-fiche {
      margin-top: 3rem;

      .signature {
        font-family: 'Bad Script', cursive;
        font-size: 30px;
        color: $rose;

        p {
          font-family: 'Bad Script', cursive;
          font-size: 30px;
          color: $rose;
          margin-top: 2rem;
        }
      }
    }

    #ancre-formu {
      a.btn-warning, a.btn-primary-dark {
        font-size: 14px;
        text-transform: uppercase;
      }

      a.btn-warning:hover {
        box-shadow: 0 14px 26px -12px rgba(255, 193, 7, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 193, 7, 0.2);
      }

      a.btn-primary-dark:hover {
        box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
      }
    }

    .alert-info a:hover {
      text-decoration: underline;
    }
  }

  .planning-prive-voyant {
    .border-planning-privee {
      border: solid $color-prive 4px;
      padding: 5px 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .planning-audiotel-voyant, .planning-prive-voyant {
    h2 {
      margin-bottom: 5px;
      font-size: 16px;
      color: $brand-primary;
      font-style: italic;
      text-transform: lowercase;
      font-family: 'open sans', sans-serif;
      font-weight: 400;
      line-height: 24px;
    }

    .horaire {
      width: 29px;
      display: inline-block;
      text-align: center;
      font-weight: 500;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, .8);
      font-size: 14px;
      margin-right: 0.3px;
    }

    .case-heure {
      width: 11px;
      height: 26px;
      background-color: rgba(0, 0, 0, .1);
    }

    .case-heure:nth-child(2n) {
      margin-right: 3px;
    }
  }

  .planning-audiotel-voyant {
    .heure-dispo-bg-color {
      background-color: $color-audio;
    }
  }

  .planning-prive-voyant {
    .heure-dispo-bg-color {
      background-color: $color-prive;
    }

    h3 {
      margin-top: 0;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 0;
      color: $color-prive;
      padding: 2%;
    }

    h4.tarif {
      font-size: 14px;
    }
  }

  #cadre-menu {
    position: fixed;
    overflow: hidden;
    top: 35%;
    height: auto;
    font: 12px Arial;
    z-index: 100;
    transition-duration: 0.5s;
    left: -220px;

    #bordure-menu {
      float: left;
      background: $rose;
      width: 220px;
      margin: 0;
      padding: 10px 20px;
      font-size: 15px;
      color: $white;
      height: auto;

      span {
        margin: 0;
        font-size: 19px;
        color: $white;
      }
    }

    #bouton-menu {
      float: right;
      height: inherit;
      background: $bleu-shadow;
      background: -moz-linear-gradient(top, $bleu-shadow 0%, $rose 100%);
      background: -webkit-linear-gradient(top, $bleu-shadow 0%, $rose 100%);
      background: linear-gradient(to bottom, $bleu-shadow 0%, $rose 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a0d9d2', endColorstr='#fa5f6d', GradientType=0);
      color: $white;
      font-size: 28px;
      padding: 10px;
      border-radius: 0 5px 5px 0;
    }

    a {
      display: block;
      color: $white;
      text-transform: uppercase;
      font-family: 'open sans condensed', sans-serif;
      font-weight: bold;
      letter-spacing: 0.5px;
      height: 30px;
      vertical-align: baseline;
      transition-duration: 0.5s;
      margin-top: 5px;

    }

    .sep-link {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $white;
    }

    a:hover {
      transform: translateX(10px);
      transition-duration: 0.5s;
    }
  }

  #cadre-menu:hover {
    left: 0;
    transition-duration: 0.5s;
  }

}

.section-voyance {
  .numero-prive {
    border: solid 4px $brand-primary;
    padding: 10px 25px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      font-size: 22px;
    }

    i.fa {
      color: $bleu-fonce;
    }

    a:hover {
      color: $bleu-fonce;
    }
  }

  h2 {
    color: $brand-primary;
  }
}

/**** avis details + blog + liste avis ****/
.contenu-avis {
  .bloc-avis {
    padding: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #000;

    .infos-avis {
      font-style: italic;
      padding: 5px 20px 5px 15px;
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
        color: $brand-primary;
      }
    }

    .text-avis {
      padding: 0 20px;
      text-align: justify;
    }

    i.fa {
      margin-right: 2px;
    }
  }

  .block-pagination {
    width: 250px;
    margin: 30px auto;

    .active {
      a {
        background-color: $bleu-fonce;
      }
    }
  }
}

/**** Planning voyance ****/
.section-voyance-audiotel, .section-voyance-privee {
  .jour-prev {
    position: absolute;
    left: -10px;
    top: 40px;
    z-index: 1000;
  }

  .jour-suivant {
    position: absolute;
    right: -12px;
    top: 40px;
  }

  .date-picker {
    .one-date {
      border-right: solid 1px $white;
      color: $brand-primary;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 14px;
      letter-spacing: 0.1em;
      cursor: pointer;
      background-color: #ddd;
    }

    .active {
      color: $white;
      font-size: 16px;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .date-planning {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      a {
        color: $black-color;
      }
    }
  }

  .liste-voyants .fiche-voyant .lien-detail img.nouveau {
    object-position: left top;
    width: 52px;
    position: absolute;
  }
}

.section-voyance-audiotel {
  .date-picker {
    .active {
      background-color: $color-audio;
    }

    .active:after {
      border-top: 10px solid $color-audio;
    }
  }
}

.section-voyance-privee .date-picker {
  .active {
    background-color: $bleu-fonce;
  }

  .active:after {
    border-top: 10px solid $bleu-fonce;
  }
}

.explications {
  h5 {
    color: $bleu-fonce;
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
  }
}

.cache_voyance h3 {
  text-align: center;
}

.avisManuel {
  .yellow {
    color: #ffc516;
  }

  .notValid .btn-warning {
    font-size: 1.2rem;
  }
}

/** Listing Voyants bundles/VoyanceSiteBundle/operateur/list.hmtl.twig **/

#listVoyants {
  #blockVoyant {
    .tarif {

      & p {
        font-size: 0.67rem;
        margin-top: 0;
        text-align: left;
        margin-bottom: 0;
      }
    }

    .cardImgVoyant-container {
      position: relative;
      overflow: hidden;
      aspect-ratio: 3 / 4;
      height: 250px;
      max-height: 130px;
      width: auto;
      justify-self: center;
      margin: 15px 5px 15px 15px;
    }

    .cardImgVoyant {
      object-fit: cover;
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }



  }
}

@media screen and (max-width: 575px) {
  #listVoyants, .section-voyance-privee, .section-voyance-audiotel {
    padding-left: 0;
    padding-right: 0;
  }
}
#listVoyantsAjax {
  #blockVoyant {
    .tarif {

      & p {
        font-size: 0.67rem;
        margin-top: 0;
        text-align: left;
        margin-bottom: 0;
      }
    }

    //.specialite {
    //  min-height: 44px;
    //}
    //
    //.certification {
    //  min-height: 30px;
    //}

    .cardImgVoyant-container {
      position: relative;
      overflow: hidden;
      aspect-ratio: 3 / 4;
      height: 250px;
      max-height: 130px;
      width: auto;
      justify-self: center;
      margin: 15px 5px 15px 15px;
    }

    .cardImgVoyant {
      object-fit: cover;
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

#listVoyantsAjax, #listVoyants {
  @media screen and (min-width: 576px) {
    .cardImgVoyant-container {
      max-height: 200px!important;
    }
  }
  @media screen and (min-width: 768px) {
    .cardImgVoyant-container {
      max-height: 140px!important;
    }
  }
  @media screen and (min-width: 992px) {
    .cardImgVoyant-container {
    max-height: 115px!important;
  }
}
  @media screen and (min-width: 1024px) {
    .cardImgVoyant-container {
      max-height: 120px!important;
    }
  }
  @media screen and (min-width: 1280px) {
    .cardImgVoyant-container {
      max-height: 150px!important;
    }
  }
  @media screen and (min-width: 1400px) {
    .cardImgVoyant-container {
      max-height: 125px!important;
    }
  }
  @media screen and (min-width: 1920px) {
    .cardImgVoyant-container {
      max-height: 180px!important;
    }
  }
}

