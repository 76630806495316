///*====================================================
//// BLOG
////===================================================*/
//.bloc-blog{
//  h5 {
//    color: white;
//  }
//}
//.blog_detail .breadcrumb {
//  background-color: $bleu-fonce;
//}
//.blog_detail h1, .blog_detail h2 {
//  color: $bleu-fonce;
//}
//.blog_detail h1,{
//  font-size: 35px;
//}
//.blog_detail li{
//  margin-right: 1%;
//}
//.blog_detail a{
//  color: #000;
//}
//.breadcrumb > .active {
//  color: #777777;
//}
//.blog_detail aside h2 {
//  color: #FFFFFF;
//  background-color: $bleu-fonce;
//  padding:10px;
//}
//.blog_listing h2 {
//  color: $bleu-fonce;
//  font-size: 20px;
//}
//.blog_listing a {
//  color: #FFFFFF;
//  background-color: $bleu-fonce;
//  padding: 5px;
//}
//.menu-recherche ul{
//  margin-left: -32%;
//  font-size: 13px;
//}
//.maincontent img {
//  width: auto;
//  height: 100%;
//  margin-bottom: 4%;
//}
//.acc-horoscope.blog {
//  margin-top: 19%;
//}
//.nav-horoscope {
//  float: none!important;
//  margin-bottom: 6%;
//  margin-left: 0;
//}
//
//
//.nav-horoscope {
//  float: left;
//  margin-bottom: 6%;
//  margin-left: 0%;
//  margin-top: 21%;
//}
//
//.acc-horoscope .categories-horoscope h1 {
//  text-align: center;
//  font-size: 26px;
//  color: #FA5F6D;
//  background-color: $bleu-fonce;
//  margin-top: 20px;
//  padding: 7px;
//}
//
//.acc-horoscope .categories-horoscope .citation h1 {
//  text-align: center;
//  font-size: 26px;
//  color: #fff;
//  background-color: $bleu-fonce;
//  margin-top: 20px;
//  padding: 7px;
//}
//
//.acc-horoscope .categories-horoscope p {
//  text-align: justify;
//  border: solid 0px #f3f3f3 !important;
//  padding: 15px;
//}
//
//
//.acc-menu {
//  margin-bottom: 20px;
//}
//.acc-menu.blog {
//  margin-top: 19%;
//}
//.nav-menu {
//  float: none;
//}
//.acc-menu .nav-menu h3 {
//  text-align: center;
//  background-color: #FA5F6D;
//  padding: 15px;
//  color: white;
//  margin-top: 0;
//  text-transform: uppercase;
//  letter-spacing: 2px;
//  font-size: 20px;
//  margin-bottom: 10px;
//}
//.acc-menu .nav-menu ul {
//  padding: 0;
//  margin: 0;
//}
//.acc-menu .nav-menu ul li.active {
//  background-color: #A0D9D2;
//}
//.acc-menu .nav-menu ul li {
//  list-style-type: none;
//  background-color: rgba(0, 0, 0, 0.1);
//  padding: 10px;
//  margin-bottom: 5px;
//  text-align: center;
//  text-transform: uppercase;
//  letter-spacing: 2px;
//  font-size: 11px;
//  font-weight: 500;
//  transition-duration: 0.5s;
//  line-height: 14px;
//}
//.acc-menu .nav-menu ul li a {
//  color: black;
//}

/*====================================================
// BLOG
//===================================================*/
#introBlog{
  h5 {
    color: white;
  }
  .item_list.radius_shadow h5 {
    background-color: $rose;
    text-align: center;
    border-radius: 10px;
    padding: 2%;
  }
}
.blog_detail {
  .blog-avis {
    border: 1mm ridge $bleu-fonce;
    padding: 3%;
  }
  h1, h2{
    color: $bleu-fonce;
  }
  h1{
    font-size: 35px;
  }
  a{
    color: $black-color;
  }
  form #commentaire_form_content:focus{
    box-shadow: none;
    border-color: $rose;
  }
}

.acc-menu.blog {
  margin-top: 19%;
}