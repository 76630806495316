/*====================================================
// COULEURS
//===================================================*/
/* COULEUR PRIVÉE */
.bg_color_1 {
  background-color: $rose;
}
/* COULEUR AUDIOTEL */
.bg_color_2 {
  background-color: $color-audio;
}
/* COULEUR SITE */
.bg_color_3 {
  background-color: $bleu-fonce;
}
.bg_color_4 {
  background-color: $grey-light;
}
.bg_color_5 {
  background-color: $bleu-fonce;
}
/* COULEUR PRIVÉE */
.color_1 {
  color: $rose;
}
/* COULEUR AUDIOTEL */
.color_2 {
  color: $color-audio;
}
/* COULEUR SITE */
.color_3 {
  color: $bleu-fonce;
}
.color_4 {
  color: $grey-light;
}
.color_5 {
  color: $bleu-fonce;
}