/*********** HEADER ***********/
header {
  a{
    color: $brand-primary;
    text-decoration: none;
    margin-right: 0;
  }
  a:hover{
    text-decoration: none;
    color: $brand-primary-80;
    transition-duration: 0.5s;
  }
  .header-top{
    height: 0;
    background-color: $bleu-fonce;
    .navbar-text {
      margin: 0;
      .numeros p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .numerosPrivee {
        a:hover {
          color: $rose;
          font-weight: bold;
        }
      }
    }
  }
  .navbar {
    color: $gray;
    margin-bottom: 0;
    padding: 0;
    border: 0;
    border-radius: 0 0 3px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
    /* Menu Mobile */
    .navbar-translate{
      width: 100%;
      position: relative;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: transform 0.7s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: transform 0.7s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: transform 0.7s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: transform 0.7s cubic-bezier(0.685, 0.0473, 0.346, 1);
      .logo{
        width: 200px;
      }
      /* icon burger-menu mobile */
      .navbar-toggler {
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0 0.75rem 1rem 0;
        .animated-icon {
          width: 22px;
          height: 2px;
          position: relative;
          margin: 0;
          outline: 0;
          display: block;
          border-radius: 1px;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .5s ease-in-out;
          -moz-transition: .5s ease-in-out;
          -o-transition: .5s ease-in-out;
          transition: .5s ease-in-out;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            background: $gray;
          }

          span:nth-child(1) {
            top: 0;
          }

          span:nth-child(2) {
            top: 6px;
          }

          span:nth-child(3) {
            top: 12px;
          }
        }
        .animated-icon.open {
          span:nth-child(1) {
            top: 9px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }
          span:nth-child(2) {
            opacity: 0;
          }

          span:nth-child(3) {
            top: 9px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
    .navbar-toggler:focus{
      box-shadow: none;
    }
    .navbar-nav .nav-item .nav-link {
      position: relative;
      color: $gray;
      padding: 0.9375rem;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      border-radius: 3px;
      line-height: 20px;
      margin-left: 0;
      margin-right: 0;
      .fa {
        font-size: 1.25rem;
        max-width: 24px;
        position: relative;
        top: 2px;
        margin-top: -4px;
        margin-right: 4px;
      }
    }

    #navbarNavDropdown.navbar-collapse{
      position: fixed;
      top: 0;
      height: 100vh;
      width: 230px;
      right: 0;
      margin-right: 0;
      z-index: 1032;
      visibility: visible;
      background-color: $gray;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      /*Transition menu*/
      -webkit-transform: translate3d(230px, 0, 0);
      -moz-transform: translate3d(230px, 0, 0);
      -o-transform: translate3d(230px, 0, 0);
      -ms-transform: translate3d(230px, 0, 0);
      transform: translate3d(230px, 0, 0);
      -webkit-transition: all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1);
      &::after {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        display: block;
        background-color: $white;
        content: "";
        z-index: 1;
      }
      .navbar-nav {
        position: relative;
        z-index: 3;
        .nav-item {
          padding: 5px 0 10px 20px;
          .nav-link {
            margin: 5px 0;
          }
          .nav-link:hover{
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.01), 0 7px 10px -5px $bleu-shadow;
            background-color: $gray-800;
          }
          &:after {
            width: calc(100% - 30px);
            content: "";
            display: block;
            height: 1px;
            margin-left: 15px;
            background-color: $white-500;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
          .dropdown-toggle {
            display: flex;
            align-content: center;
          }
          .dropdown-toggle:after{
            position: fixed;
            right: 50px;
            margin-top: 8px;
          }
          .dropdown-toggle.show::after {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
          .dropdown-menu {
            border: none;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.01), 0 7px 10px -5px $bleu-shadow;
            scrollbar-color: $rose $white-500;
          }
          /* Pour les navigateurs basés sur WebKit, comme Chrome et Safari */
          .dropdown-menu::-webkit-scrollbar {
            width: 3px;
          }
          .dropdown-menu::-webkit-scrollbar-thumb {
            background-color: $rose;
            border-radius: 8px;
          }
        }
        .nav-item.active {
          .nav-link, .nav-link:hover, .nav-link:active, .nav-link:focus {
            background-color: $bleu-fonce;
            color: $white;
            margin-right: 16px;
          }
          .dropdown-menu{
            .nav-link{
              background-color: transparent;
              color: $gray;
            }
            .nav-link:hover{
              box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.01), 0 7px 10px -5px $bleu-shadow;
              background-color: $gray-800;
            }
            .nav-link.custom, .nav-link.custom:hover, .nav-link.custom:active, .nav-link.custom:focus {
              background-color: $bleu-fonce;
              color: $white;
              right: 0.2rem;
            }
          }
        }
      }
    }
    #navbarNavDropdown.navbar-collapse.show{
      right: 230px;
    }
  }
  /* Header mobile */
  #header-mobile-phone {
    padding: 0;
    .numeros-mobile {
      font-weight: bold;
      text-transform: lowercase;
      .btn:not(:disabled):not(.disabled) {
        padding: 5px 0;
        margin: 0;
      }
      a{
        font-size: .75rem;
        .fa-phone{
          color: $white;
          font-size: small;
        }
      }
    }
  }
  .mobile-nav-bottom {
    padding: 5px;
    background-color: $bleu-fonce;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    text-align: center;
    i {
      color: white;
      font-size: x-large;
      cursor: pointer;
    }
  }
}
.nav-open {
  .navbar-translate{
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
}

.recherche-mobile {
  z-index: 1500;
  display: block;
  background-color: $bleu-fonce;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: height 0.5s ease;
  height: 50px;
  padding: 10px;
  .bmd-form-group {
    padding-top: 0;
    width: 82%;
  }
  i {
    font-size: 23px;
    color: white;
  }
  i.fa-window-close {
    font-size: 27px;
    margin-left: 4px;
  }
}
.recherche-close {
  height: 0;
  display: none;
}

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;
}