/**** Cookies ****/
#container-cookie-consent {
  position: fixed;
  overflow: hidden;
  top: 85%;
  height: auto;
  font: 12px Arial;
  z-index: 100;
  transition-duration: 0.5s;
  left:-220px;
  #border-cookie-consent {
    float: left;
    background: $rose;
    width: 220px;
    margin: 0;
    padding: 17.5px 20px;
    font-size: 15px;
    outline: none;
    border:none;
    color: $white;
    height: auto;
  }
  &:hover{
    left: 0;
    transition-duration: 0.5s;
  }
  #bouton-menu {
    float: right;
    height: inherit;
    background: rgb(160,217,210); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(160,217,210,1) 0%, rgba(250,95,109,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(160,217,210,1) 0%,rgba(250,95,109,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(160,217,210,1) 0%,rgba(250,95,109,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0d9d2', endColorstr='#fa5f6d',GradientType=0 ); /* IE6-9 */
    color: $white;
    font-size: 28px;
    padding: 10px;
    border-radius: 0 5px 5px 0;
  }
}

body {
  background-color: $white;
  font-family: 'Noto Sans JP', sans-serif;
}

p {
  font-size: 14px;
  margin: 0 0 10px;
}

h1, h2, h3, h4, h5 {
  font-family: 'open sans condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
}

h1{
  font-size: 30px;
}
h2{
  font-size: 27px;
}
h3{
  font-size: 25px;
}
h4{
  font-size: 20px;
}

h2 {
  line-height: 40px;
}

a, a:active, a:hover {
  color: $rose;
  text-decoration: none
}

a.white-underline {
  color: white;
  text-decoration: underline;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-75 {
  margin-top: 75px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-75 {
  margin-bottom: 75px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.container {
  max-width: 1200px;
}

.justify{
  text-align: justify;
}

.msg-alert {
  position: relative;
  z-index: 1500;
  top: 20px;
  margin-bottom: 0;
}

.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
  text-align: justify;
}
.alert[role="alert"]{
  font-weight: bold;
}

.alert.alert-info {
  background-color: $brand-primary-10;
  color: $brand-primary;
  a {
    color: $brand-primary;
  }
}

.alert.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  p a{
    color: #856404;
    text-decoration: underline;
  }
}
.favicon{
  background-color: $color-audio;
  color: $white;
}
.btn{
  font-size: 0.75rem;
  padding: 1rem;
}
.btn-primary{
  color: $white;
  text-transform: uppercase;
  &:hover{
    color: $white;
    box-shadow: 0 14px 26px -12px rgba(0, 123, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 123, 255, 0.2);
  }
}
.btn.btn-info {
  text-transform: uppercase;
  color: $white;
}

.btn.btn-primary-dark {
  background-color: $bleu-fonce;
}

.btn.btn-primary-dark:hover {
  background-color: $bleu-fonce;
}

.btn-audiotel{
  color: $white;
  text-transform: uppercase;
  &:hover{
    color: $white;
    box-shadow: 0 14px 26px -12px rgba(165,16,120,0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(165,16,120, 0.2);
  }
}

.border-grey {
  border: solid 3px $gray-600;
  padding: 20px;
  height: 100%;
}

.separator {
  border-top: solid 1px $brand-primary-30;
  color: #ddd;
}

.separateur {
  width: 30px;
  height: 4px;
  background-color: $rose;
  margin-bottom: 12px;
}

hr.separateur-planning{
  width: 90%;
  border: solid 1px $rose;
  opacity: 1;
}

.separateur-centre {
  margin-left: auto;
  margin-right: auto;
}

p strong {
  font-weight: bold;
  color: $brand-primary;
}


.text-white {
  color: $white;
}
.bloc-shadow{
  margin-top: 9%;
  padding: 3%;
  margin-bottom: 6%;
  border: none;
  -moz-box-shadow: 0 0 10px 2px #d5d5d5;
  -webkit-box-shadow: 0 0 10px 2px #d5d5d5;
  box-shadow: 0 0 10px 2px #d5d5d5;
  cursor: pointer;
}

.numero-prive {
  font-size: 30px;
}

// MOTEUR DE RECHECHE
li.select2-results__option a {
  color: $white;
  text-transform: uppercase;
}
li.select2-results__option a:hover {
  color: $black-color;
  text-transform: uppercase;
  text-decoration: underline;
}
.select2-container--open .select2-dropdown--below {
  background-color: $bleu-fonce;
}
.select2-container--open .select2-dropdown--above {
  background-color: $bleu-fonce;
}
/**** Toast voyant ****/
#toast-title, i {
  .yellow-audiotel {
    color: $color-audio;
  }
  .yellow-private {
    color: $bleu-fonce;
  }
}
#toast-time, span {
  .private {
    background-color: $bleu-fonce;
  }
  .audiotel {
    background-color: $color-audio;
  }
}
.toast{
  background-color:$white;
}
.toast-footer{
  a.private {
    color:$bleu-fonce;
    border-color:$bleu-fonce;
  }
  a.private:hover{
    color:$bleu-fonce;
    border-color:$bleu-fonce;
    background-color:rgba(122, 184, 176, 0.1);
  }
  a.audiotel{
    color:$color-audio;
    border-color:$color-audio;
  }
  a.audiotel:hover{
    color:$color-audio;
    border-color:$color-audio;
    background-color:rgba(165, 16, 120, 0.1);
  }
}

//*********ERROR404**********//
.error {
  margin-top: 5%;
  margin-bottom: 5%;
}

.erreur h1 {
  background-color: $bleu-fonce;
  padding: 1%;
}
/**** Menu left ****/
.acc-menu {
  margin-bottom: 20px;
  .nav-menu {
    h3 {
      text-align: center;
      background-color: #FA5F6D;
      padding: 15px;
      color: white;
      margin-top: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 20px;
      margin-bottom: 10px;
    }
    ul {
      padding: 0;
      margin: 0;
      li.active {
        background-color: #A0D9D2;
      }
      li {
        list-style-type: none;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin-bottom: 5px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 11px;
        font-weight: 500;
        transition-duration: 0.5s;
        line-height: 14px;
        a {
          color: $black-color;
          padding: .5rem 1rem;
        }
      }
    }
  }
}
/**** Bandeau pub ****/
.bloc-info, .bloc-info2 {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 70px 0;
}
.bobo {
  cursor: pointer;
}

/**** pagination ****/
.pagination .page-item.active span {
  background-color: $bleu-fonce;
}
.pagination > .page-item > {
  .page-link {
    border: 0;
    border-radius: 30px;
    transition: all .3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: $grey;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    text-align: center;

  }
  .page-link:focus {
    box-shadow: none;
  }
}
.pagination .page-item.active span {
  background-color: $bleu-fonce;
  color: $white;
}


.stretch .bloc-num {
  margin: 20px 0;
  cursor: pointer;
  a {
    color: $black-color;
  }
}

/**** couleur numero privee ****/
h3 {
  a:hover {
    color: $bleu-fonce;
  }
}

/**** Notation Etoile ****/
.fa-star {
  color: $gray-400;
}

.yellow {
  color: gold;
}

/*********** pages légales ***********/
.cadre-grey strong{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.mentions a, .politique-confi a, .cgu-cgv a, .span12 a  {
  color: $black-color;
}

.cgu-cgv strong{
  font-weight: bold;
}
.cgu-cgv p, li{
  font-size: 14px;
}


.autres-cabinets a {
  color: $black-color;
}
.autres-cabinets a:hover, #cabinetVoyance li a:hover, #plan-site .span12 ul li a:hover{
  color: $bleu-fonce;
}

.erreur .bloc-erreur {
  margin-top: 9%;
  padding: 2%;
  margin-bottom: 6%;
  border: none;
  -moz-box-shadow: 0 0 10px 2px #d5d5d5;
  -webkit-box-shadow: 0 0 10px 2px #d5d5d5;
  box-shadow: 0 0 10px 2px #d5d5d5;
  cursor: pointer;
}


/*** TEST NOUVEAU BLOCK VOYANT ***/

.h-10 {
  height: 10% !important;
}
.h-8 {
  height: 8% !important;
}
 .h-70 {
   height: 72% !important;
 }
 .fs-7 {
   font-size: 0.75rem!important;
 }
.fa.fa-star.yellow {
  color: gold;
}
.mh-0 {
  min-height: unset!important;
}
.logos-katleen .mini-logo {
  float: unset!important;
}
.description {
  font-size: 0.9rem;
}
@media screen and (min-width: 1920px){
  #listVoyants, #listVoyantsAjax {
    max-width: 1920px!important;
    margin: 0 auto;
  }
}

/** shiny effect **/

.shiny-effect {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.shiny-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 50%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  transform: skewX(-25deg);
  animation: shine 10s linear infinite;
  animation-delay: 5s;
}

/* Animation */
@keyframes shine {
  0% {
    left: -50%;
  }
  10% {
    left: 150%; /* Brilliance completes */
  }
  100% {
    left: 150%; /* Wait for the rest of the interval */
  }
}

/** surchage slick-slide **/
#listVoyantsAjax .slick-track {
  height: 355px!important;
  & .slick-slide{
    & div:not([class]) {
      height: 100%!important;
    }
  }
}

@media screen and (max-width: 575px) {
 #listVoyantsAjax .slick-track{
    height: 325px!important;
    //min-height: 325px!important;
  }
  .logo-paiement {
    height: 25px;
    width: auto;
  }

}

// Remove scroll on cookie consent modal description
#cc-main .cm--cloud .cm__desc
{
  max-height: none;
}

a.text-gold, a.text-gold:active, a.text-gold:hover {
  color:#856404;
}

.getBackButtonPosition {
  background: rgb(160,217,210); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(160,217,210,1) 0%, rgba(250,95,109,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(160,217,210,1) 0%,rgba(250,95,109,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(160,217,210,1) 0%,rgba(250,95,109,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0d9d2', endColorstr='#fa5f6d',GradientType=0 ); /* IE6-9 */
  padding: 10px;
  border-radius: 0 5px 5px 0;
  position: fixed;
  top: 15%;
  left: 0;
  z-index: 2000;
  & i {
    color: white;
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .getBackButtonPosition {
    top: 11%;
  }
}

