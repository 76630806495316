//.numerologie{
//  .defi-1{
//    color: #14AFEF;
//  }
//  .defi-2{
//    color: #FFB236;
//  }
//  .defi-3{
//    color: #49D64A;
//  }
//  .bloc-presentation{
//    //background: url(../img/intro-bg-3-defis.jpg) no-repeat 0 0;
//    min-height: 350px;
//    padding: 150px 0 0 280px;
//  }
//  .bloc-defi{
//    padding: 20px;
//  }
//  form{
//    display: block;
//    margin-left: auto;
//    margin-right: auto;
//    input{
//      width: auto;
//      text-align: center;
//    }
//    .btn-defi{
//      text-align: center;
//      background-color: #A0D9D2;
//      padding: 10px 20px;
//      color: white;
//      font-variant: small-caps;
//      letter-spacing: 1px;
//      font-size: 16px;
//      display: block;
//      margin-left: auto;
//      margin-right: auto;
//    }
//  }
//  .bloc-result-defis{
//    background: url(../img/header_result_bg.jpg) no-repeat center;
//    height: 400px;
//    position: relative;
//    .naissance{
//      padding-top: 100px;
//    }
//    .date-naissance{
//      padding-right: 50px;
//      padding-top: 27px;
//      color: white;
//      letter-spacing: 1px;
//    }
//    .vous-avez{
//      padding-right: 150px;
//      padding-top: 50px;
//    }
//    .nbre-defis{
//      color: white;
//    }
//    .defis{
//      padding-left: 120px;
//      padding-bottom: 20px;
//    }
//  }
//  .resultats-defis{
//    .bloc-resultat{
//      h1{
//        display: inline-block;
//        margin-left: 3px;
//      }
//      h4{
//        display: inline-block;
//      }
//      .col-gauche{
//        background-color: #f3f3f3;
//        padding: 20px;
//        margin-top: 30px;
//      }
//    }
//  }
//  #app_birthday_month, #app_birthday_day, #app_birthday_year {
//    margin: 0 auto;
//    padding: 0;
//  }
//}
//
//.test{
//  img{
//    display: block;
//    margin-left: auto;
//    margin-right: auto;
//    max-width: 100%;
//  }
//  form{
//    label{
//      font-size: 16px;
//    }
//  }
//
//}
//
///*====================== NUMEROLOGIE ======================*/
//
//.stretch {
//  display: flex;
//  flex-flow: row wrap;
//  align-items: stretch;
//
//  .bloc-num {
//    margin: 20px 0;
//    cursor: pointer;
//    a {
//      color: #000000;
//    }
//    .border-grey {
//      border: solid 3px #f3f3f3;
//      padding: 20px;
//      height: 100%;
//      p {
//        padding: 20px 0 0;
//      }
//    }
//  }
//}
//select.form-control:not([size]):not([multiple]) {
//  height: 100%;
//  width: 100%!important;
//}
//@media screen and (min-width: 1024px) {
//  .legal_card {
//    background-color: rgba(0, 0, 0, 0.1);
//    padding: 3%!important;
//  }
//}
//@media screen and (min-width: 320px) {
//  #app_birthday_day{
//    height: calc(1.5em + 0.75rem + 2px);
//    width: 100% !important;
//    color: #AAAAAA;
//  }
//  #app_birthday_month {
//    height: calc(1.5em + 0.75rem + 2px);
//    width: 100% !important;
//    color: #AAAAAA;
//  }
//  #app_birthday_year{
//    height: calc(1.5em + 0.75rem + 2px);
//    width: 100% !important;
//    color: #AAAAAA;
//  }
//
//}
.numerologie-intro .stretch .bloc-num .border-grey p {
  padding: 20px 0 0;
}

.numerologie {
  #app_birthday_day, #app_birthday_month, #app_birthday_year {
    height: calc(1.5em + 0.75rem + 2px);
    width: 100%;
    color: #AAAAAA;
    border: none;
  }
  form .btn-defi {
    text-align: center;
    background-color: $bleu-shadow;
    padding: 10px 20px;
    color: white;
    font-variant: small-caps;
    letter-spacing: 1px;
    font-size: 16px;
  }
  .defi-1 {
    color: #14AFEF;
  }
  .defi-2 {
    color: #FFB236;
  }
  .defi-3 {
    color: #49D64A;
  }
  .bloc-defi {
    padding: 20px;
  }

  .bloc-result-defis {
    background: url(../img/header_result_bg.jpg) no-repeat center;
    height: 400px;
    position: relative;
    .naissance {
      padding-top: 100px;
    }
    .date-naissance {
      padding-right: 50px;
      padding-top: 27px;
      color: $white;
      letter-spacing: 1px;
    }
    .vous-avez {
      padding-right: 150px;
      padding-top: 50px;
    }
    .nbre-defis {
      color: $white;
    }
    .defis {
      padding-left: 120px;
      padding-bottom: 20px;
    }
  }

  .resultats-defis {
    .bloc-resultat {
      h1 {
        display: inline-block;
        margin-left: 3px;
      }
      h4 {
        display: inline-block;
      }
      .col-gauche {
        background-color: $gray-600;
        padding: 20px;
        margin-top: 30px;
      }
    }
  }
}